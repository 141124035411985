import React from "react";
import ReactPaginate from "react-paginate";
export default function Pagination({ totalpagination, paginationevent }) {
  const handlePageClick = (event) => {
    console.log(event.selected);
    paginationevent(event.selected + 1);
  };
  return (
    <div className="pagination-wrapper next-btn-fix">
      <ReactPaginate
        breakLabel="..."
        nextLabel={"next"}
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={totalpagination}
        previousLabel={"previous"}
        renderOnZeroPageCount={null}
        // containerClassName={'pagination justify-content-center'}
        //   pageClassName={'page-item'}
        //   pageLinkClassName={'page-link'}
        //   previousClassName={'page-item'}
        //   previousLinkClassName={'page-link'}
        //   nextClassName={'page-item'}
        //   nextLinkClassName={'page-link'}
        //   breakClassName={'page-item'}
        //   breakLinkClassName={'page-link'}
        //   activeClassName={'active'}
      />
    </div>
  );
}
