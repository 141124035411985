import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getclublistAction, playerlistAction } from "../Redux/Action/AdminstratorAction";
import moment from "moment";
import { Encryptedid } from "../Util/BcruptEncyptid";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import Pagination from "../Component/Pagination";
export default function Players() {
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [perpage, setPerpage] = useState(10);
  const [tab, setTab] = useState(1);
  const [serachkeyval, setSerachkeyval] = useState("");
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      playerlistAction({
        perpage: perpage,
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval]);

  const playerlist = useSelector((state) => state.Administrator.playerlist);
  const admindetails = (id) => {
    const bcrypt = Encryptedid(id);
    navigate("/Club-Profile-Overview/" + bcrypt);
  };
  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };
  const paginationevent = (pag) => {
    setPageno(pag);
  };

  console.log(playerlist,"playerlistplayerlist")
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All Players</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={2}
                xl={3}
                lg={3}
                md={4}
                className="d-flex justify-content-center"
              >
                <div className="date-range-picker">
                  <DateRangePicker placeholder="Select Date Range" />
                </div>
              </Col>
              <Col xxl={2} xl={3} lg={3} md={4}>
                <Form.Select aria-label="Default select example" >
                  <option>Active Status</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>

              <Col
                xxl={5}
                xl={3}
                lg={3}
                md={12}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/create-player">CREATE NEW</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        {/* <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="#" className={tab == 1 && "active-tab"}>
                  ACTIVE
                  <span>10</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 2 && "active-tab"}
                  onClick={() => {
                    setTab(2);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  PENDING
                  <span>10</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 3 && "active-tab"}
                  onClick={() => {
                    setTab(3);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  CLOSED
                  <span>10</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container> */}
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
            <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;

                  return (
                    rangeStart +
                    " - " +
                    (playerlist?.Totalplayer > 10
                      ? rangeEnd
                      : playerlist?.Totalplayer) +
                    " of " +
                    playerlist?.Totalplayer?.toLocaleString("en-US")
                  );
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive main">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>#</th>
                <th>JOINED</th>
                <th>FIRST NAME</th>
                <th>SURNAME</th>
              

                <th>NO. DONATORS </th>
                <th>PENDING DONATIONS</th>
                <th>PAID OUT</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>

            {
              playerlist?.alllist?.map(function(clubobject,i){
                return(
                  <tr>
                  <td>{i + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}</td>
                    <td>
                      {moment(clubobject?.createdAt).format("DD/MM/YYYY")}
                      <br />
                      <span className="time">
                        {" "}
                        {moment(clubobject?.createdAt).format("HH:mm")}
                      </span>
                    </td>
                <td>
                  <Link to="/player-profile-overview"> {clubobject?.firstname} </Link>
                </td>
                <td>
                  <div>{clubobject?.surname}</div>
                </td>
              
                <td>---</td>
                <td>---</td>
                <td>---</td>
                <td>Active</td>
              </tr>
                )
              })
            }
           
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        totalpagination={playerlist?.totalpagination}
        paginationevent={paginationevent}
      />
    </Layout>
  );
}
