import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";

export default function CustomerProfileProductOverview() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>
              <img src={require("../Assets/Images/dummy.svg").default} />
              Tony Jones Smith
            </h2>
            <p>USER # 00001</p>
          </div>
          <div>
            <div className="date-created">
              <h4>
                CREATED: <span>01/02/2023</span>
              </h4>
              <h4>
                LAST ACTIVE: <span>01/02/2023</span>
              </h4>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Members">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col lg={8}>
              <div className="product-tab-cmn-btns">
                <Link className="buttons" to="/Customer-Profile-Overview">
                  Overview
                </Link>

                <Link
                  className="active buttons"
                  to="/Customer-Profile-product-Overview"
                >
                  Products
                </Link>
                <Link className="buttons" to="/Customer-Profile-Transactions">
                  Transactions
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="cmn-background">
        <div className="product-tab">
          <ul>
            <li>
              <Link to="#" className="active-tab">
                ALL <span>3</span>
              </Link>
            </li>
            <li>
              <Link to="#">
                ACTIVE <span> 2</span>
              </Link>
            </li>
            <li>
              <Link to="#">
                CLOSED <span>1</span>
              </Link>
            </li>
          </ul>
        </div>
      </Container>

      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 - 3 of 3 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>ACCOUNT #</th>
                <th>OPENED</th>
                <th>PRODUCT NAME</th>
                <th>RATE</th>
                <th>DIRECT DEBIT </th>
                <th>DEPOSITED</th>
                <th>EARNED</th>
                <th>CLUB</th>
                <th>STATUS</th>
                <th>BALANCE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="/Customer-Product-Detail">CLUBERLY ISA 5.9%</Link>
                </td>
                <td>5.9%</td>
                <td>Yes</td>
                <td>£10,200.00</td>
                <td>£230.00</td>
                <td>Manchester United FC</td>
                <td>Active</td>
                <td>
                  <h6>£10,430.00</h6>
                </td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="/Customer-Product-Detail">CLUBERLY ISA 5.9%</Link>
                </td>
                <td>5.9%</td>
                <td>Yes</td>
                <td>£10,200.00</td>
                <td>£230.00</td>
                <td>Manchester United FC</td>
                <td>Active</td>
                <td>
                  <h6>£10,430.00</h6>
                </td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="/Customer-Product-Detail">CLUBERLY ISA 5.9%</Link>
                </td>
                <td>5.9%</td>
                <td>Yes</td>
                <td>£10,200.00</td>
                <td>£230.00</td>
                <td>Manchester United FC</td>
                <td>Active</td>
                <td>
                  <h6>£10,430.00</h6>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>
    </Layout>
  );
}
