import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  addandupdateblogAction,
  addandupdatecategoryAction,
  categorydetailsAction,
  createproductAction,
  uploadcategoryimagesAction,
  uploadproductimagesAction,
} from "../Redux/Action/AdminstratorAction";
import { useState } from "react";
import Editor from "../Component/TextEditor";
import { EditorState } from "draft-js";
import Accordion from "react-bootstrap/Accordion";
import { Decryptedid } from "../Util/BcruptEncyptid";
export default function EditCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState();
  const [selectedDImage, setSelectedDImage] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const { id } = useParams();

  var idvalue = Decryptedid(atob(id));



  useEffect(() => {
    dispatch(categorydetailsAction({ id: idvalue })).then(function (data) {
      if (data.payload) {
        var Details = data.payload;

        for (const [key, value] of Object.entries(Details)) {
          if (key != "_id" && key != "icon" ) {
            formik.setFieldValue(key, value, { strict: false });
          }
        if(key=="icon"){
            setSelectedDImage(value)
            formik.setFieldValue(key, value, { strict: false });
        }

          
        }
      }
    });
  }, [idvalue]);





  const formik = useFormik({
    initialValues: {
      title: "",
      icon: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      icon: Yup.string().required("Icon is required"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
        values.id=idvalue
      values.icon = selectedDImage;

      dispatch(addandupdatecategoryAction(values)).then(function () {
        navigate("/manage-category");
      });
    },
  });



  console.log(formik.errors)
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    setEditorLoaded(true);
  }, []);
  console.log(convertedContent, "convertedContentconvertedContent");
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Create Category</h2>
            <p>NEW POST</p>
          </div>
          <div></div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/manage-category">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <form onSubmit={formik.handleSubmit}>
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={8}>
              <Row>
                <Col lg={12}>
                  <div className="customer-form-new">
                    <h2>DETAILS</h2>
                    <hr className="cmn-border" />
                    <div className="customer-form-inner mt-0 pt-0">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3 mt-4">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              name="title"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.title}
                            />
                            {formik.submitCount > 0 && formik.errors.title && (
                              <div className="errors">
                                {formik.errors.title}
                              </div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="mb-3 image-upload-main">
                            <Form.Label>Upload Icon</Form.Label>
                            <div className="input-image-show">
                              <Form.Control
                                type="file"
                                multiple
                                placeholder="Upload Image"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  let formdata = new FormData();

                            
                                    formdata.append(
                                      "icon",
                                      e.target.files[0]
                                    );
                                  

                                  dispatch(
                                    uploadcategoryimagesAction(formdata)
                                  ).then(function (payload) {

                                    formik.setFieldValue('icon',payload.payload.images,{strict:false})
                                    setSelectedDImage(payload.payload.images);
                                  });
                                }}
                                name="icon"
                                value={formik.values.coverimage}
                              />

                              <p>Upload Image</p>
                              {formik.submitCount > 0 && formik.errors.icon && (
                              <div className="errors">
                                {formik.errors.icon}
                              </div>
                            )}
                            </div>
                          </Form.Group>
                          <div className="d-flex create-product-images justify-content-center">
                
                     {
                      selectedDImage !="" && 
                
                                <img
                                  className="upload-img-show"
                                  src={selectedDImage}
                                  alt="Thumb"
                                  crossOrigin="anonymous"
                                />
                                   }
                          
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="blog-save-btn">
                            <div className="cmn-btn">
                              <button type="submit">Create</button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            {/* <Col lg={4} className="h-100">
              <div className="product-overview-right">
                <div className="product-overview-box">
                  <h2>PUBLISH STATUS</h2>
                  <Form.Select
                    aria-label="Default select example"
                    name="dispatch"
                  >
                    <option value="0">DRAFT</option>
                    <option value="1">PUBLISH</option>
                  </Form.Select>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </form>
    </Layout>
  );
}
