import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";

export default function ProductsDetailsMembers() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>CLUBERLY ISA 5.9%</h2>
            <p>PRODUCT ID # 00001</p>
          </div>
          <div>
            <div className="date-created">
              <h4>
                CREATED: <span>01/02/2023</span>
              </h4>
              <h4>
                LAST ACTIVE: <span>01/02/2023</span>
              </h4>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Products">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col lg={8}>
              <div className="product-tab-cmn-btns">
                <Link className="buttons" to="/Products-Details-Overview">
                  Overview
                </Link>
                <Link className="active buttons" to="/Products-Detail-Members">
                  Members
                </Link>
                <Link className="buttons" to="/Products-Details-Clubs">
                  Clubs
                </Link>
                <Link className="buttons" to="/Products-Detail-Transactions">
                  Transactions
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <div className="product-cmn-color">
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Keyword Search.." />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={3} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Joining Date Range</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>

              <Col xxl={2} xl={3} lg={3} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>City</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>
              <Col xxl={2} xl={3} lg={3} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Status</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="#" className="active-tab">
                  ALL <span>30,264</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  NEW TODAY <span> 232</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  ACTIVE <span>3,459</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing 1 - 10 of 30,264 results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>#</th>
                <th>OPENED</th>
                <th>NAME</th>
                <th>CITY</th>
                <th>DIRECT DEBIT </th>
                <th>BALANCE</th>
                <th>DEPOSITED</th>
                <th>EARNED</th>
                <th>WITHDRAWN</th>
                <th>RAISED TOT.</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="#">Tony Jones Smith</Link>
                </td>
                <td>LONDON</td>
                <td>Yes</td>
                <td>£10,430.00</td>
                <td>£10,200.00</td>
                <td>£230.00</td>
                <td>£0.00</td>
                <td>£230.00</td>
                <td>OPEN</td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="#">Tony Jones Smith</Link>
                </td>
                <td>LONDON</td>
                <td>Yes</td>
                <td>£10,430.00</td>
                <td>£10,200.00</td>
                <td>£230.00</td>
                <td>£0.00</td>
                <td>£230.00</td>
                <td>OPEN</td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="#">Tony Jones Smith</Link>
                </td>
                <td>LONDON</td>
                <td>Yes</td>
                <td>£10,430.00</td>
                <td>£10,200.00</td>
                <td>£230.00</td>
                <td>£0.00</td>
                <td>£230.00</td>
                <td>OPEN</td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="#">Tony Jones Smith</Link>
                </td>
                <td>LONDON</td>
                <td>Yes</td>
                <td>£10,430.00</td>
                <td>£10,200.00</td>
                <td>£230.00</td>
                <td>£0.00</td>
                <td>£230.00</td>
                <td>OPEN</td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="#">Tony Jones Smith</Link>
                </td>
                <td>LONDON</td>
                <td>Yes</td>
                <td>£10,430.00</td>
                <td>£10,200.00</td>
                <td>£230.00</td>
                <td>£0.00</td>
                <td>£230.00</td>
                <td>OPEN</td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="#">Tony Jones Smith</Link>
                </td>
                <td>LONDON</td>
                <td>Yes</td>
                <td>£10,430.00</td>
                <td>£10,200.00</td>
                <td>£230.00</td>
                <td>£0.00</td>
                <td>£230.00</td>
                <td>OPEN</td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="#">Tony Jones Smith</Link>
                </td>
                <td>LONDON</td>
                <td>Yes</td>
                <td>£10,430.00</td>
                <td>£10,200.00</td>
                <td>£230.00</td>
                <td>£0.00</td>
                <td>£230.00</td>
                <td>OPEN</td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="#">Tony Jones Smith</Link>
                </td>
                <td>LONDON</td>
                <td>Yes</td>
                <td>£10,430.00</td>
                <td>£10,200.00</td>
                <td>£230.00</td>
                <td>£0.00</td>
                <td>£230.00</td>
                <td>OPEN</td>
              </tr>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="#">Tony Jones Smith</Link>
                </td>
                <td>LONDON</td>
                <td>Yes</td>
                <td>£10,430.00</td>
                <td>£10,200.00</td>
                <td>£230.00</td>
                <td>£0.00</td>
                <td>£230.00</td>
                <td>OPEN</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>
    </Layout>
  );
}
