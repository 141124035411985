import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getclublistAction } from "../Redux/Action/AdminstratorAction";
import moment from "moment";
import { Encryptedid } from "../Util/BcruptEncyptid";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import Pagination from "../Component/Pagination";
export default function Clubs() {
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [perpage, setPerpage] = useState(10);
  const [tab, setTab] = useState(1);
  const [serachkeyval, setSerachkeyval] = useState("");
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );


  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getclublistAction({
        perpage: perpage,
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval]);

  const getclublists = useSelector((state) => state.Administrator.getclublists);
  const admindetails = (id) => {
    const bcrypt = Encryptedid(id);
    navigate("/Club-Profile-Overview/" + bcrypt);
  };
  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };
  const paginationevent = (pag) => {
    setPageno(pag);
  };
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All Clubs</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={2}
                xl={3}
                lg={3}
                md={4}
                className="d-flex justify-content-center"
              >
                <div className="date-range-picker">
                  <DateRangePicker placeholder="Select Date Range" />
                </div>
              </Col>
              <Col xxl={2} xl={3} lg={3} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Active Status</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>

              <Col
                xxl={5}
                xl={3}
                lg={3}
                md={12}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/create-club">CREATE NEW</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link
                  to="#"
                  className={tab == 1 && "active-tab"}
                  onClick={() => {
                    setTab(1);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  ALL
                  <span>{getclublists?.Totalclub}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 2 && "active-tab"}
                  onClick={() => {
                    setTab(2);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  INVITED
                  <span>{getclublists?.invitedclun}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 3 && "active-tab"}
                  onClick={() => {
                    setTab(3);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  SUSPENDED
                  <span>{getclublists?.suspendedclub}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 4 && "active-tab"}
                  onClick={() => {
                    setTab(4);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  ACTIVE
                  <span>{getclublists?.activeclub}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 5 && "active-tab"}
                  onClick={() => {
                    setTab(5);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  DELETED
                  <span>{getclublists?.deletedclub}</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;
                  if (tab === 1) {
                    return (
                      rangeStart +
                      " -" +
                      (getclublists?.Totalclub > 10
                        ? rangeEnd
                        : getclublists?.Totalclub) +
                      " of " +
                      getclublists?.Totalclub?.toLocaleString("en-US")
                    );
                  } else if (tab === 2) {
                    return `${rangeStart} - ${
                      getclublists?.invitedclun > 10
                        ? rangeEnd
                        : getclublists?.invitedclun
                    } of  ${getclublists?.invitedclun?.toLocaleString(
                      "en-US"
                    )} `;
                  } else if (tab === 3) {
                    return `${rangeStart} - ${
                      getclublists?.suspendedclub > 10
                        ? rangeEnd
                        : getclublists?.suspendedclub
                    } of  ${getclublists?.suspendedclub?.toLocaleString(
                      "en-US"
                    )} `;
                  } else if (tab === 4) {
                    return `${rangeStart} - ${
                      getclublists?.activeclub > 10
                        ? rangeEnd
                        : getclublists?.activeclub
                    } of  ${getclublists?.activeclub?.toLocaleString(
                      "en-US"
                    )} `;
                  } else if (tab === 5) {
                    return `${rangeStart} - ${
                      getclublists?.deletedclub > 10
                        ? rangeEnd
                        : getclublists?.deletedclub
                    } of  ${getclublists?.deletedclub?.toLocaleString(
                      "en-US"
                    )} `;
                  }
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive main">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>#</th>
                <th>JOINED</th>
                <th>COMPANY NAME</th>
                <th>COMPANY REP</th>
                <th>TYPE</th>
                 
                <th>NO. DONATORS </th>
                <th>PENDING DONATIONS</th>
                <th>PAID OUT</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {getclublists?.alllist?.map(function (clubobject, i) {
                return (
                  <tr key={i} onClick={() => {
                        admindetails(clubobject?._id);
                      }}>
                   <td>{i + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}</td>
                    <td>
                      {moment(clubobject?.createdAt).format("DD/MM/YYYY")}
                      <br />
                      <span className="time">
                        {" "}
                        {moment(clubobject?.createdAt).format("HH:mm")}
                      </span>
                    </td>
                    <td
                      onClick={() => {
                        admindetails(clubobject?._id);
                      }}
                    >
                      <div> {clubobject?.clubname?.toUpperCase()} </div>
                    </td>
                    <td
                      onClick={() => {
                        admindetails(clubobject?._id);
                      }}
                    >
                      <div>
                        {clubobject?.firstname + " " + clubobject?.lastname}
                      </div>
                    </td>
                   
                    <td>
                      {(() => {
                        if (clubobject?.club_type === 0) {
                          return "Club";
                        } else if (clubobject?.club_type === 1) {
                          return "Charity";
                        } else if (clubobject?.club_type === 2) {
                          return "School";
                        } 
                      })()}
                    </td>

                    <td>---</td>
                    <td>---</td>
                    <td>---</td>

                    <td>
                      {(() => {
                        if (clubobject?.invitedstatus === 0) {
                          return "Invited";
                        } else if (clubobject?.invitedstatus === 1) {
                          return "Active";
                        } else if (clubobject?.invitedstatus === 2) {
                          return "Suspended";
                        } else if (clubobject?.invitedstatus === 3) {
                          return "Deleted";
                        }
                      })()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

     
      <Pagination
        totalpagination={getclublists?.totalpagination}
        paginationevent={paginationevent}
      />
    

    </Layout>
  );
}
