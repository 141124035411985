import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";

export default function NewPlayerTransactions() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Club Transaction / Payout</h2>
            <p>ADMIN</p>
          </div>
          <div>
            <div className="date-created">
              <h4>
                MEMBER SINCE: <span>01/02/2023</span>
              </h4>
              <h4>
                LAST ACTIVE: <span>01/02/2023</span>
              </h4>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={12}>
              <div className="product-tab-left">
                <Link to="/players">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form-new">
                  <h2>PAYOUT DETAIL</h2>
                  <hr className="cmn-border" />
                  <div className="customer-form-inner">
                    <Row>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Select Payout Method</Form.Label>
                          <Form.Select aria-label="Default select example">
                            <option>select </option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Cheque / Bank Transaction ID</Form.Label>
                          <Form.Control type="text" placeholder="Type Here" />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>Amount</Form.Label>
                          <Form.Control type="number" placeholder="£0.00" />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>Tax Added</Form.Label>
                          <Form.Control type="number" placeholder="£0.00" />
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Cheque / Bank Transaction ID</Form.Label>
                          <Form.Control type="text" placeholder="Type Here" />
                        </Form.Group>
                      </Col>
                      <Col
                        lg={12}
                        className="d-flex justify-content-center align-items-center mt-4 pt-4"
                      >
                        <div className="form-save-btn-new">
                          <button type="button">SAVE</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>ADMIN ACTIONS</h2>
                <button type="button" className="edit-product-new">
                  DELETE
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
