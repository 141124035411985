import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  addandupdateclubAction,
  categoryandsubcategorylistAction,
} from "../Redux/Action/AdminstratorAction";
export default function CreatePlayer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  useEffect(() => {
    dispatch(categoryandsubcategorylistAction());
  }, []);

  const categorysub = useSelector((state) => state.Administrator.categorysub);

  const formik = useFormik({
    initialValues: {
      clubname: "",
      email: "",
      mobile: "",
      firstname: "",
      lastname: "",
      address1: "",
      category_id: "",
      subcategory_id: "",
    },
    validationSchema: Yup.object().shape({
      clubname: Yup.string().required("Club Name is required"),
      category_id: Yup.string().required("Club Type is required"),
      subcategory_id: Yup.string().required("Sub Club Type is required"),

      firstname: Yup.string().required("First name is required"),
      lastname: Yup.string().required("First name is required"),
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Email is required"),
      mobile: Yup.number(),
      address1: Yup.string().required("Address line is required"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(addandupdateclubAction(values)).then(function () {
        navigate("/Clubs");
      });
    },
  });
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>
              <img src={require("../Assets/Images/dummy.svg").default} />
              Create club
            </h2>
          </div>
          <div></div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Clubs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12}>
                  <div className="customer-form">
                    <h2>CLUB INFORMATION</h2>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Club Name</Form.Label>
                          <Form.Control
                            type="text"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="clubname"
                            value={formik.values.clubname}
                          />
                          {formik.submitCount > 0 && formik.errors.clubname && (
                            <div className="errors">
                              {formik.errors.clubname}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="email"
                            value={formik.values.email}
                          />
                          {formik.submitCount > 0 && formik.errors.email && (
                            <div className="errors">{formik.errors.email}</div>
                          )}
                        </Form.Group>
                      </Col>
                      {/* <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Company Representative</Form.Label>
                          <Form.Control
                            type="text"
                            name="companyrepresentative"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.companyrepresentative}
                          />
                          {formik.submitCount > 0 &&
                            formik.errors.companyrepresentative && (
                              <div className="errors">
                                {formik.errors.companyrepresentative}
                              </div>
                            )}
                        </Form.Group>
                      </Col> */}
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstname"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.firstname}
                          />
                          {formik.submitCount > 0 &&
                            formik.errors.firstname && (
                              <div className="errors">
                                {formik.errors.firstname}
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastname"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.lastname}
                          />
                          {formik.submitCount > 0 && formik.errors.lastname && (
                            <div className="errors">
                              {formik.errors.lastname}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Select Club/Charity/School Type
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="category_id"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              const selectedCategoryId = e.target.value;
                              const filteredSubcategories =
                                categorysub?.subcategory?.filter(
                                  (subcategory) =>
                                    subcategory.category_id ===
                                    selectedCategoryId
                                );
                              formik.setFieldValue("subcategory_id", ""); // Reset subcategory selection
                              formik.setFieldValue(
                                "category_id",
                                selectedCategoryId,
                                { strict: false }
                              );
                              setFilteredSubcategories(filteredSubcategories);
                            }}
                            value={formik.values.category_id}
                          >
                            <option value="">Please Select</option>
                            {categorysub?.category?.map((catobject, i) => (
                              <option key={catobject._id} value={catobject._id}>
                                {catobject.name}
                              </option>
                            ))}
                          </Form.Select>
                          {formik.submitCount > 0 &&
                            formik.errors.category_id && (
                              <div className="errors">
                                {formik.errors.category_id}
                              </div>
                            )}
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Select Type</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="subcategory_id"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values?.subcategory_id}
                          >
                            <option value="">Please Select</option>
                            {filteredSubcategories?.map((catobject, i) => (
                              <option
                                key={catobject?._id}
                                value={catobject?._id}
                              >
                                {catobject?.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            type="text"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="mobile"
                            value={formik.values.mobile}
                          />
                          {formik.submitCount > 0 && formik.errors.mobile && (
                            <div className="errors">{formik.errors.mobile}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            className="mb-2"
                            type="text"
                            name="address1"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.address1}
                          />
                          {formik.submitCount > 0 && formik.errors.address1 && (
                            <div className="errors">
                              {formik.errors.address1}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col
                        lg={6}
                        className="d-flex justify-content-end align-items-end mt-4"
                      >
                        <div className="form-save-btn">
                          <button type="submit">SAVE</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </form>
          </Col>

          <Col lg={4}>
            <div className="product-actions">
              <div className="d-flex justify-content-between align-items-center">
                <h2>DOCUMENTS</h2>
                <button className="add-btn">ADD</button>
              </div>
              <hr />
              <div className="product-action-inner">
                <h3>Certificate of Incorporation </h3>
                <p>PDF</p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  Photo ID <span>JPEG</span>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
