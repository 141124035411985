import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import Pagination from "../Component/Pagination";
export default function Products() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Products</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Keyword Search.." />
                </Form.Group>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4}>
                {/* <Form.Select aria-label="Default select example">
                      <option>Created Date Range</option>
                      <option value="1">10</option>
                      <option value="2">20</option>
                      <option value="3">30</option>
                    </Form.Select> */}
                <div className="date-range-picker">
                  <DateRangePicker placeholder="Select Date Range" />
                </div>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/Create-Product">CREATE NEW</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="#" className="active-tab">
                  ALL <span>0</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  ACTIVE <span> 0</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  PENDING <span>0</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 - 1 of 1 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive main">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>PRODUCT ID</th>
                <th>CREATED</th>
                <th>PRODUCT NAME</th>
                <th>CREATED BY</th>
                <th>OPEN </th>
                <th>CLOSED</th>
                <th>CLUBS</th>
                <th>BALANCE</th>
                <th>+/-</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="/Products-Details-Overview">
                    Cluberly Savings ISA 0%
                  </Link>
                </td>
                <td>
                  <Link to="#">@Cluberly Super Admin</Link>
                </td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>ACTIVE</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>

     
    
    </Layout>
  );
}
