import axios from "axios";


var baseURL =process.env.REACT_APP_API_URL;
console.log(baseURL,"baseURLbaseURLbaseURL")
 
const Api = axios.create({
    baseURL: baseURL,
    headers: { authorization: localStorage.getItem('accessToken')}
  });



  Api.interceptors.response.use(function (response) {
    console.log(response?.response,"response?.response?.statusresponse?.response?.statusresponse?.response?.statusresponse?.response?.status")
    if (response?.response?.status === 401) {
      // toast.error('Session Expired');
      setTimeout(() => {
        sessionStorage.clear();
        localStorage.clear()
        window.location.reload(false);
        window.location.href = '/';
      }, 1000);
    }

    return response;
  }, function (error) {

    if (error?.response?.status === 401) {
        // toast.error('Session Expired');
        setTimeout(() => {
          sessionStorage.clear();
          localStorage.clear()
          window.location.reload(false);
          window.location.href = '/';
        }, 1000);
      }
  });


  export default Api;