import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function Membersuspended({
    show,handleClose,
    type,
    deleteadmin
}) {


  return (
    <Modal show={show} onHide={handleClose} className="delete-popup">
    <Modal.Header closeButton></Modal.Header>
    <Modal.Body>
      <img src={require("../Assets/Images/delete.svg").default} />
      <p> Are you sure you want to suspend the account?</p>{" "}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={deleteadmin}>
        Yes
      </Button>
      <Button variant="primary" onClick={handleClose}>
        No
      </Button>
    </Modal.Footer>
  </Modal>
  )
}