import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Decryptedid } from "../Util/BcruptEncyptid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ClubaccountsuspendedAction,
  addandupdateclubAction,
  categoryandsubcategorylistAction,
  clubdetailsAction,
  deleteclubAction,
  uploadDocumentclubAction,
} from "../Redux/Action/AdminstratorAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import DeleteComponent from "../Component/DeleteComponent";
import Membersuspended from "../Component/Membersuspended";
import { resettemppasswordAction } from "../Redux/Action/Authaction";
import moment from "moment";
import Documentuplomodal from "../Component/Documentuplomodal";
export default function ClubProfileOverview() {
  const { id } = useParams();
  const [documentshows, setShownew] = useState(false);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [showsuspended, setShowsuspended] = useState(false);
  const [description, setDescription] = useState("");
  const [show, setShow] = useState(false);
  var idvalue = Decryptedid(atob(id));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(clubdetailsAction({ id: idvalue })).then(function (data) {
      if (data.payload) {
        var Details = data.payload;
        for (const [key, value] of Object.entries(Details)) {
          if (key != "_id") {
            formik.setFieldValue(key, value, { strict: false });
          }
        }
      }
    });
  }, [idvalue]);

  const clubdetails = useSelector((state) => state.Administrator.clubdetails);
  useEffect(() => {
    dispatch(categoryandsubcategorylistAction());
  }, []);

  const categorysub = useSelector((state) => state.Administrator.categorysub);

  const formik = useFormik({
    initialValues: {
      clubname: "",
      email: "",
      mobile: "",
      firstname: "",
      lastname: "",
      address1: "",
      club_type: "",
    },
    validationSchema: Yup.object().shape({
      clubname: Yup.string().required("Club Name is required"),
      firstname: Yup.string().required("First name is required"),
      club_type: Yup.string().required("Club Type is required"),
      lastname: Yup.string().required("First name is required"),
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Email is required"),
      mobile: Yup.number(),
      address1: Yup.string().required("Address line is required"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      values.id = idvalue;
      dispatch(addandupdateclubAction(values));
    },
  });

  const handleClose = () => setShow(false);
  //deleteclubAction

  const deleteadmin = () => {
    dispatch(
      deleteclubAction({
        id: idvalue,
      })
    ).then(function () {
      navigate("/Clubs");
      setShow(false);
    });
  };
  const handleClosesus = () => setShowsuspended(false);
  const suspend = () => {
    setShowsuspended(false);
    dispatch(
      ClubaccountsuspendedAction({
        id: idvalue,
        accountstatus: clubdetails?.accountstatus == 1 ? 0 : 1,
      })
    );
  };
  const submitdocument = (values) => {
    var appendform = new FormData();
    appendform.append("title", values.title);
    appendform.append("filename", values.filename);
    appendform.append("clubid", idvalue);

    dispatch(uploadDocumentclubAction(appendform));
    setShownew(false);
  };
  const documentclose = () => {
    setShownew(false);
  };
  const handleShownew = () => {
    setShownew(true);
  };
  const getFileExtension = (url) => {
    // Split the URL by '.'
    const parts = url.split(".");
    // Get the last part which should be the extension
    return parts[parts.length - 1].toLowerCase();
  };
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>
              <img
                src={
                  clubdetails?.image != undefined && clubdetails?.image != ""
                    ? clubdetails?.image
                    : require("../Assets/Images/dummy.svg").default
                }
                crossOrigin="anonymous"
              />
              {clubdetails?.clubname}
            </h2>
          </div>
          {/* <div>
                <div className="date-created">
                  <h4>
                    MEMBER SINCE: <span>01/02/2023</span>
                  </h4>
                  <h4>
                    LAST ACTIVE: <span>01/02/2023</span>
                  </h4>
                </div>
              </div> */}
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Clubs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col lg={8}>
              <div className="product-tab-cmn-btns">
                <Link className="active buttons" to="/Club-Profile-Overview">
                  Overview
                </Link>

                <Link className="buttons" to="/Club-Profile-Member">
                  Members
                </Link>
                <Link className="buttons" to="/Club-Profile-Transactions">
                  Transactions
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12}>
                  <div className="customer-form">
                    <h2>CLUB INFORMATION</h2>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Club Name</Form.Label>
                          <Form.Control
                            type="text"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="clubname"
                            value={formik.values.clubname}
                          />
                          {formik.submitCount > 0 && formik.errors.clubname && (
                            <div className="errors">
                              {formik.errors.clubname}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="email"
                            value={formik.values.email}
                          />
                          {formik.submitCount > 0 && formik.errors.email && (
                            <div className="errors">{formik.errors.email}</div>
                          )}
                        </Form.Group>
                      </Col>
                      {/* <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Company Representative</Form.Label>
                          <Form.Control
                            type="text"
                            name="companyrepresentative"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.companyrepresentative}
                          />
                          {formik.submitCount > 0 &&
                            formik.errors.companyrepresentative && (
                              <div className="errors">
                                {formik.errors.companyrepresentative}
                              </div>
                            )}
                        </Form.Group>
                      </Col> */}
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstname"
                            value={formik.values.firstname}
                          />
                          {formik.submitCount > 0 &&
                            formik.errors.firstname && (
                              <div className="errors">
                                {formik.errors.firstname}
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastname"
                            value={formik.values.lastname}
                          />
                          {formik.submitCount > 0 && formik.errors.lastname && (
                            <div className="errors">
                              {formik.errors.lastname}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Select Club/Charity/School Type
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="category_id"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              const selectedCategoryId = e.target.value;
                              const filteredSubcategories =
                                categorysub?.subcategory?.filter(
                                  (subcategory) =>
                                    subcategory.category_id ===
                                    selectedCategoryId
                                );
                              formik.setFieldValue("subcategory_id", ""); // Reset subcategory selection
                              formik.setFieldValue(
                                "category_id",
                                selectedCategoryId,
                                { strict: false }
                              );
                              setFilteredSubcategories(filteredSubcategories);
                            }}
                            value={formik.values.category_id}
                          >
                            <option value="">Please Select</option>
                            {categorysub?.category?.map((catobject, i) => (
                              <option key={catobject._id} value={catobject._id}>
                                {catobject.name}
                              </option>
                            ))}
                          </Form.Select>
                          {formik.submitCount > 0 &&
                            formik.errors.category_id && (
                              <div className="errors">
                                {formik.errors.category_id}
                              </div>
                            )}
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Select Type</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="subcategory_id"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values?.subcategory_id}
                          >
                            <option value="">Please Select</option>

                            {filteredSubcategories.length == 0
                              ? categorysub?.subcategory?.map(
                                  (catobject, i) => (
                                    <option
                                      key={catobject?._id}
                                      value={catobject?._id}
                                    >
                                      {catobject?.name}
                                    </option>
                                  )
                                )
                              : filteredSubcategories?.map((catobject, i) => (
                                  <option
                                    key={catobject?._id}
                                    value={catobject?._id}
                                  >
                                    {catobject?.name}
                                  </option>
                                ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            type="text"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="mobile"
                            value={formik.values.mobile}
                          />
                          {formik.submitCount > 0 && formik.errors.mobile && (
                            <div className="errors">{formik.errors.mobile}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            className="mb-2"
                            type="text"
                            name="address1"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.address1}
                          />
                          {formik.submitCount > 0 && formik.errors.address1 && (
                            <div className="errors">
                              {formik.errors.address1}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col
                        lg={6}
                        className="d-flex justify-content-end align-items-end mt-4"
                      >
                        <div className="form-save-btn">
                          <button type="submit">Update</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </form>

            <Row>
              <Col lg={12}>
                <div className="profile-products-sec">
                  <h2>ASSOCIATED PRODUCTS </h2>
                  <hr />
                  <div className="product-main-box">
                    <Row>
                      <Col lg={6} className="d-flex align-items-center">
                        <div className="product-inner">
                          <h3>CLUBERLY SAVINGS ISA </h3>
                          <p>Donators: 434</p>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex justify-content-end">
                        <div className="product-inner-right">
                          <button className="active-btn">Active</button>
                          <p>Total Raised: £22,000.00</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div className="product-main-box">
                    <Row>
                      <Col lg={6} className="d-flex align-items-center">
                        <div className="product-inner">
                          <h3> </h3>
                          <p></p>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex justify-content-end">
                        <div className="product-inner-right">
                          <p></p>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="product-main-box">
                    <Row>
                      <Col lg={6} className="d-flex align-items-center">
                        <div className="product-inner">
                          <h3></h3>
                          <p></p>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex justify-content-end">
                        <div className="product-inner-right">
                          <p></p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="profile-products-sec">
                  <h2>ADMIN NOTES </h2>
                  <hr />
                  <div className="admin-box-main">
                    {clubdetails?.adminnotes?.map(function (object, i) {
                      return (
                        <>
                          <div className="admin-box-inner">
                            <div className="admin-box-top">
                              <h3>
                                {object?.addedby?.name}{" "}
                                <span>
                                  {moment(object?.adddate).format("DD/MM/YYYY")}
                                </span>{" "}
                                <span>
                                  {moment(object?.adddate).format("HH:mm")}
                                </span>
                              </h3>
                            </div>
                            <p style={{ whiteSpace: "pre-line" }}>
                              {object?.description}
                            </p>
                          </div>
                          <hr />
                        </>
                      );
                    })}

                    <div className="admin-box-inner">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          value={description}
                          rows={3}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          placeholder="Type Here"
                        />
                        <Col
                          lg={6}
                          className="d-flex justify-content-end align-items-end mt-3"
                        >
                          <div class="delete-btn">
                            <button
                              type="submit"
                              onClick={() => {
                                dispatch(
                                  addandupdateclubAction({
                                    description: description,
                                    id: idvalue,
                                    addedby: localStorage.getItem("userid"),
                                  })
                                );
                                setDescription("");
                              }}
                            >
                              SUBMIT
                            </button>
                          </div>
                        </Col>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>ADMIN ACTIONS</h2>
                <button
                  type="button"
                  className="edit-product"
                  onClick={() => {
                    dispatch(
                      resettemppasswordAction({
                        id: clubdetails?._id,
                      })
                    );
                  }}
                >
                  RESET PASSWORD / NEW ACCOUNT
                </button>

                <button
                  type="button"
                  className="edit-product"
                  onClick={setShowsuspended}
                >
                  {clubdetails?.accountstatus == 1
                    ? "SUSPEND ACCOUNT"
                    : "ACTIVATE ACCOUNT"}
                </button>
                <button
                  type="button"
                  className="edit-product"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  DELETE / CLOSE ACCOUNT
                </button>
              </div>
            </div>
            <div className="product-actions">
              <h2>ACTIVITY</h2>
              <hr />
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
            </div>
            <div className="product-actions">
              <div className="d-flex justify-content-between align-items-center">
                <h2>DOCUMENTS</h2>
                <button className="add-btn" onClick={handleShownew}>
                  ADD
                </button>
              </div>
              <hr />
              {clubdetails?.documents?.map(function (filepbject, i) {
                return (
                  <div className="product-action-inner">
                    <h3>{filepbject?.title} </h3>
                    <p>
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_IMAGE_URL + filepbject?.filename
                        }
                      >
                        {getFileExtension(filepbject?.filename).toUpperCase()}
                      </a>
                    </p>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>

      <DeleteComponent
        show={show}
        handleClose={handleClose}
        type={"club"}
        Id={idvalue}
        deleteadmin={deleteadmin}
      />

      <Membersuspended
        show={showsuspended}
        handleClose={handleClosesus}
        type={"customer"}
        Id={idvalue}
        deleteadmin={suspend}
      />
      <Documentuplomodal
        documentshows={documentshows}
        documentclose={documentclose}
        submitdocument={submitdocument}
      />
    </Layout>
  );
}
