
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../ApiConfig";
import { toast } from "react-toastify";



export const  AdminlistAction =createAsyncThunk('Adminlist',async(obj)=>{

        const  data  = await Api.post("/adminauth/adminlist", obj);

                if(data.data.status==201){

                    return data.data.adminlist
                }
    })


    export const  ProfiledetailsAction =createAsyncThunk('Profiledetails',async(obj)=>{

        const  data  = await Api.get(`/adminauth/profiledetails?id=${obj}`);

                if(data.data.status==201){

                     return data.data.admindetails
                }

    })


    export const  UserlistAction =createAsyncThunk('Userlist',async(obj)=>{

        const  data  = await Api.post("/adminauth/userlist", obj);

                if(data.data.status==201){

                    return data.data.userlist
                }

    })
    

    export const  deleteAdminuserAction =createAsyncThunk('deleteAdminuser',async(obj)=>{

        const  data  = await Api.post(`/adminauth/deleteAdminuser`,obj);

                if(data.data.status==201){

                     return data.data.admindetails
                }

    })


    
    export const memberprofiledetailsAction =createAsyncThunk('memberprofiledetails',async(obj)=>{

        const  data  = await Api.post(`/adminauth/memberprofiledetails`,obj);

          if(data.data.status==201){
               return data.data.memberdetails
          }

})


    export const  updatememberprofileAction =createAsyncThunk('updatememberprofile',async(obj)=>{

              const  data  = await Api.post(`/adminauth/updatememberprofile`,obj);

                if(data.data.status==201){
                     return data.data.userdetails
                }

    })


    export const addandupdateclubAction =createAsyncThunk('addandupdateclub',async(obj)=>{

        const  data  = await Api.post(`/adminauth/addandupdateclub`,obj);

          if(data.data.status==201){
               return data.data.saveinformation
          }else{
               toast.error(data.data.message) 
          }

     })

export const getclublistAction =createAsyncThunk('getclublist',async(obj)=>{

    const  data  = await Api.post(`/adminauth/getclublist`,obj);

      if(data.data.status==201){
           return data.data.getclublists
      }

})





export const accountsuspendedAction =createAsyncThunk('accountsuspended',async(obj)=>{

     const  data  = await Api.post(`/adminauth/accountsuspended`,obj);
 
       if(data.data.status==201){
            return data.data.userlist
       }
 
 })

 export const clubdetailsAction =createAsyncThunk('clubdetails',async(obj)=>{

     const  data  = await Api.post(`/adminauth/clubdetails`,obj);
 
       if(data.data.status==201){
            return data.data.getclublists
       }
 
 })

 export const deleteclubAction =createAsyncThunk('deleteclub',async(obj)=>{

     const  data  = await Api.post(`/adminauth/deleteclub`,obj);
 
       if(data.data.status==201){
            return data.data.getclublists
       }
 
 })

 export const dashBoardAdminAction =createAsyncThunk('dashBoardAdmin',async(obj)=>{

     const  data  = await Api.get(`/adminauth/dashBoardAdmin`);
 
       if(data.data.status==200){
            return data.data.dashboard
       }
 
 })


 export const ClubaccountsuspendedAction =createAsyncThunk('Clubaccountsuspended',async(obj)=>{

     const  data  = await Api.post(`/adminauth/Clubaccountsuspended`,obj);

       if(data.data.status==201){
       

            return data.data.clubsuspend
       }
 
 })


 export const categoryandsubcategorylistAction =createAsyncThunk('categoryandsubcategorylist',async(obj)=>{

     const  data  = await Api.get(`/adminauth/categoryandsubcategorylist`);

       if(data.data.status==200){
       

            return data.data.Listcategorysub
       }
 
 })
 
 
 

 
 
 //blog action section 

 export const addandupdateblogAction =createAsyncThunk('addandupdateblog',async(obj)=>{
          const  data  = await Api.post(`/adminauth/addandupdateblog`,obj);
               if(data.data.status==201){
                    return data.data.saveinformation
               }
               })
export const deleteblogAction =createAsyncThunk('deleteblog',async(obj)=>{
     const  data  = await Api.post(`/adminauth/deleteblog`,obj);
          if(data.data.status==201){
               return data.data.saveinformation
          }
          })
export const getbloglistAction =createAsyncThunk('getbloglist',async(obj)=>{
     const  data  = await Api.post(`/adminauth/getbloglist`,obj);
          if(data.data.status==201){
               return data.data.getbloglists
          }
          })
export const blogdetailsAction =createAsyncThunk('blogdetails',async(obj)=>{
     const  data  = await Api.post(`/adminauth/blogdetails`,obj);
          if(data.data.status==201){
               return data.data.getblogdetails
          }
          })


 //end blog action section 
 

 //upload document

 export const uploadDocumentAction =createAsyncThunk('uploadDocument',async(obj)=>{
     const  data  = await Api.post(`/adminauth/uploadDocument`,obj);
          if(data.data.status==201){
               return data.data.userdetails
          }
          })
 
 
export const uploadDocumentclubAction =createAsyncThunk('uploadDocumentclub',async(obj)=>{
const  data  = await Api.post(`/adminauth/uploadDocumentclub`,obj);
     if(data.data.status==201){
          return data.data.getclubdetails
     }
     })
          

     
     
//push





    
export const addpushnotificationAction =createAsyncThunk('addpushnotification',async(obj)=>{
     const  data  = await Api.post(`/adminauth/addpushnotification`,obj);
  
          if(data.data.status==200){
      
               return data.data.notificatoinadd
          }
          })
export const listnotificationAction =createAsyncThunk('listnotification',async(obj)=>{
const  data  = await Api.post(`/adminauth/listnotification`,obj);
     if(data.data.status==200){
          return data.data.notificationlist
     }
     })
     export const pushnotificationallowmemberlistAction =createAsyncThunk('pushnotificationallowmemberlist',async(obj)=>{
          const  data  = await Api.post(`/adminauth/pushnotificationallowmemberlist`,obj);
               if(data.data.status==200){
                    return data.data.userlist
               }
               })
     
    
    //mailing


     export const addmailingAction =createAsyncThunk('addmailing',async(obj)=>{
          const  data  = await Api.post(`/adminauth/addmailing`,obj);
     
               if(data.data.status==200){
          
                    return data.data.mailingadd
               }
               })
               
     export const listmailingAction =createAsyncThunk('listmailing',async(obj)=>{
     const  data  = await Api.post(`/adminauth/listmailing`,obj);
          if(data.data.status==200){
               return data.data.mailinglist
          }
          })
     export const mailallowmemberlistAction =createAsyncThunk('mailallowmemberlist',async(obj)=>{
     const  data  = await Api.post(`/adminauth/mailallowmemberlist`,obj);
          if(data.data.status==200){
               return data.data.userlist
          }
          })



          //product

          export const createproductAction =createAsyncThunk('createproduct',async(obj)=>{
          const  data  = await Api.post(`/adminauth/createproduct`,obj);
               if(data.data.status==200){
                    return data.data.userlist
               }
               })
          

               
          export const uploadproductimagesAction =createAsyncThunk('uploadproductimages',async(obj)=>{
               const  {data}  = await Api.post(`/adminauth/uploadproductimages`,obj);
               return data;
                  
                    })


               
          export const productmainimagesaveAction =createAsyncThunk('productmainimagesave',async(obj)=>{
               const  {data}  = await Api.post(`/adminauth/productmainimagesave`,obj);
               return data;
                    
                    })
          


     export const getproductlistAction =createAsyncThunk('getproductlist',async(obj)=>{

          const  {data}  = await Api.post(`/adminauth/getproductlist`,obj);
          console.log(data)
          
               if(data.status==201){
                    return data.getproductlist
               }
          
          })


          export const productdetailsAction =createAsyncThunk('productdetails',async(obj)=>{

               const  {data}  = await Api.post(`/adminauth/productdetails`,obj);
        
               
                    if(data.status==201){
                         return data.getproductdetails
                    }
               
               })
     
          //category

          export const uploadcategoryimagesAction =createAsyncThunk('uploadcategoryimages',async(obj)=>{
               const  {data}  = await Api.post(`/adminauth/uploadcategoryimages`,obj);
               return data;
                  
                    })

          export const addandupdatecategoryAction =createAsyncThunk('addandupdatecategory',async(obj)=>{
          const  {data}  = await Api.post(`/adminauth/addandupdatecategory`,obj);
               if(data.status==201){
                    return data.saveinformation
               }
               })
               
          export const getcategorylistAction =createAsyncThunk('getcategorylist',async(obj)=>{
               const  {data}  = await Api.post(`/adminauth/getcategorylist`,obj);
                    if(data.status==201){
             
                         return data.getcategorylists
                    }
                    })
               
          export const categorydetailsAction =createAsyncThunk('categorydetails',async(obj)=>{
          const  {data}  = await Api.post(`/adminauth/categorydetails`,obj);
               if(data.status==201){
          
                    return data.getcategorydetails
               }
          })    
          
          
//manage order
export const orderlistAction =createAsyncThunk('orderlist',async(obj)=>{
     const  {data}  = await Api.post(`/adminauth/orderlist`,obj);
          if(data.status==201){
     
               return data.getorderlistdata
          }
     })    
     
export const orderdetailsAction =createAsyncThunk('orderdetails',async(obj)=>{
     const  {data}  = await Api.post(`/adminauth/orderdetails`,obj);
          if(data.status==201){
     
               return data.orderdetailsdata
          }
     })    
     
     export const updatetheorderdetailsAction =createAsyncThunk('updatetheorderdetails',async(obj)=>{
          const  {data}  = await Api.post(`/adminauth/updatetheorderdetails`,obj);
               if(data.status==201){
                    newordercountAction()
                    return data.saveinfomation
               }
          })  
     
          
          export const newordercountAction =createAsyncThunk('newordercount',async()=>{
               const  {data}  = await Api.get(`/adminauth/newordercount`);
                    if(data.status==200){
               
                         return data.totalneworder
                    }
               })  
     export const playerlistAction =createAsyncThunk('playerlist',async(obj)=>{
          const  {data}  = await Api.post(`/adminauth/playerlist`,obj);
               if(data.status==201){
          
                    return data.player
               }
          })      
          
export const reportlistAction =createAsyncThunk('reportlist',async(obj)=>{
     const  {data}  = await Api.post(`/adminauth/reportlist`,obj);
          if(data.status==201){
     
               return data.report
          }
     })   
          

               
     export const getexportreportlistdataAction =createAsyncThunk('getexportreportlistdata',async(obj)=>{
          const  {data}  = await Api.post(`/adminauth/getexportreportlistdata`,obj);
               if(data.status==201){
          
                    return data.report
               }
          })   

          
     