import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { UserlistAction } from "../Redux/Action/AdminstratorAction";
import moment from "moment";
import { Encryptedid } from "../Util/BcruptEncyptid";
import MemberlistsComponent from "../Component/MemberlistsComponent";
import { useState } from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
export default function Members() {
  const dispatch = useDispatch();
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const [active, setSactive] = useState("");
  const [tab, setTab] = useState(1);
  const [dateRange, setDateRange] = useState([null, null]);
  const [senddateRange, setsenDateRange] = useState({ from: null, to: null });
  const [perpage, setPerpage] = useState(10);
  const navigate = useNavigate();
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );
  useEffect(() => {
    dispatch(
      UserlistAction({
        perpage: perpage,
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
        senddateRange: senddateRange,
        active: active,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval, senddateRange, active]);

  const memberlists = useSelector((state) => state.Administrator.memberlists);
  const paginationevent = (pag) => {
    setPageno(pag);
  };

  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };

  const handleDateRangeChange = (value) => {

    console.log(value,"valuevalue")
    if (value != null) {
      setsenDateRange({
        from: moment(value[0]).format("YYYY-MM-DD"),
        to: moment(value[1]).format("YYYY-MM-DD"),
      });
    } else {
      setsenDateRange({
        from: null,
        to: null,
      });
    }

    setDateRange(value);
    // Perform any other actions you need here
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All Members</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              <Col className="d-flex justify-content-center">
                <div className="date-range-picker">
                  <DateRangePicker
                    value={dateRange}
                    onChange={handleDateRangeChange} // Call handleDateRangeApply when the DateRangePicker is closed
                  />
                </div>
              </Col>
              <Col className="d-flex justify-content-center">
                <Form.Select aria-label="Default select example">
                  <option>Product Purchases</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>
              <Col className="d-flex justify-content-center">
                <Form.Select aria-label="Default select example">
                  <option>City</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>
              <Col className="d-flex justify-content-center">
                {/* <Form.Select aria-label="Default select example" value={active} onChange={(e)=>{
                  setSactive(e.target.value)
                }}>
                  <option>Select  Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </Form.Select> */}
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link
                  to="#"
                  className={tab == 1 && "active-tab"}
                  onClick={() => {
                    setTab(1);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  ALL <span>{memberlists?.Totaluser}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 2 && "active-tab"}
                  onClick={() => {
                    setTab(2);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  NEW TODAY <span>{memberlists?.newtoday}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 3 && "active-tab"}
                  onClick={() => {
                    setTab(3);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  ACTIVE <span>{memberlists?.activeuser}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 4 && "active-tab"}
                  onClick={() => {
                    setTab(4);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  INACTIVE <span>{memberlists?.inactiveuser}</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;
                  if (tab === 1) {
                    return (
                      rangeStart +
                      " -" +
                      (memberlists?.Totaluser > 10
                        ? rangeEnd
                        : memberlists?.Totaluser) +
                      " of " +
                      memberlists?.Totaluser?.toLocaleString("en-US")
                    );
                  } else if (tab === 2) {
                    return `${rangeStart} - ${
                      memberlists?.newtoday > 10
                        ? rangeEnd
                        : memberlists?.newtoday
                    } of  ${memberlists?.newtoday?.toLocaleString("en-US")} `;
                  } else if(tab === 2) {
                    return `${rangeStart} - ${
                      memberlists?.activeuser > 10
                        ? rangeEnd
                        : memberlists?.activeuser
                    } of  ${memberlists?.activeuser?.toLocaleString("en-US")} `;
                  }else{
                    return `${rangeStart} - ${
                      memberlists?.inactiveuser > 10
                        ? rangeEnd
                        : memberlists?.inactiveuser
                    } of  ${memberlists?.inactiveuser?.toLocaleString("en-US")} `; 
                  }
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <MemberlistsComponent
          memberlists={memberlists}
          paginationevent={paginationevent}
          uniqueid={uniqueid}
        />
      </Container>
    </Layout>
  );
}
