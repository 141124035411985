import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import CustomerProductChart from "../Component/CustomerProductChart";
export default function CustomerProductDetail() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>CLUBERLY ISA 5.9%</h2>
            <p>PRODUCT ID # 00001</p>
          </div>
          <div>
            <div className="date-created">
              <h4>
                CREATED: <span>01/02/2023</span>
              </h4>
              <h4>
                LAST ACTIVE: <span>01/02/2023</span>
              </h4>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Members">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col lg={8}>
              <div className="product-tab-cmn-btns">
                <Link className="active buttons" to="#">
                  Overview
                </Link>
                <Link className="buttons" to="/Products-Detail-Members">
                  Members
                </Link>
                <Link className="buttons" to="/Products-Details-Clubs">
                  Clubs
                </Link>
                <Link className="buttons" to="/Products-Detail-Transactions">
                  Transactions
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>BALANCE</h6>
                  <h2>£22,000.00</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>AVAILABLE BALANCE</h6>
                  <h2>£21,900.00</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>INTEREST RATE</h6>
                  <h2>5.9%</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>INTEREST EARNED</h6>
                  <h2>£1,630.00</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>TOTAL WITHDRAWN</h6>
                  <h2>£2000.00</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>TOTAL RAISED</h6>
                  <h2>£230.00</h2>
                </div>
              </Col>

              <Col lg={12}>
                <div className="product-graph">
                  <CustomerProductChart />
                </div>
              </Col>

              <Col lg={12}>
                <div className="product-cmn-color">
                  <Container fluid>
                    <div className="cmn-top-fields">
                      <Row>
                        <Col xxl={4} xl={4} lg={4} md={4}>
                          <Form.Group>
                            <Form.Control
                              type="search"
                              placeholder="Keyword Search.."
                            />
                          </Form.Group>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4}>
                          <Form.Select aria-label="Default select example">
                            <option>Product</option>
                            <option value="1">10</option>
                            <option value="2">20</option>
                            <option value="3">30</option>
                          </Form.Select>
                        </Col>

                        <Col xxl={4} xl={4} lg={4} md={4}>
                          <Form.Select aria-label="Default select example">
                            <option>Transaction Type</option>
                            <option value="1">10</option>
                            <option value="2">20</option>
                            <option value="3">30</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                  <hr />
                  <Container fluid>
                    <div className="product-transactions">
                      <div className="d-flex flex-wrap justify-content-between">
                        <div className="transact-amount">
                          <h3>TOTAL DEPOSITED: </h3>
                          <span>£12,304.00</span>
                        </div>
                        <div className="transact-amount">
                          <h3>TOTAL WITHDRAWN: </h3>
                          <span>£2,000.00</span>
                        </div>
                      </div>
                      <div>
                        <div className="transact-amount">
                          <h3>ACCOUNTS TOTAL BALANCE: </h3>
                          <span>£22,000.00</span>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <Container fluid>
                  <div className="results-sec">
                    <Row>
                      <Col lg={6} className="d-flex align-items-center">
                        <div className="show-results">
                          Showing 1 - 3 of 3 results
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="select-result d-flex align-items-center justify-content-end">
                          <span>Results per page</span>{" "}
                          <Form.Select aria-label="Default select example">
                            <option>10</option>
                            <option value="1">11</option>
                            <option value="2">12</option>
                            <option value="3">13</option>
                          </Form.Select>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Container>
                <div class="table-responsive">
                  <Table size="sm" className="table-cmn">
                    <thead>
                      <tr>
                        <th>TRANSACTION #</th>
                        <th>DATE</th>
                        <th>TIME</th>
                        <th>TRANSACTION TYPE</th>
                        <th>AMOUNT </th>
                        <th>BALANCE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>09:12</td>
                        <td>DEPOSIT</td>
                        <td className="total-amount">+ £100.00</td>
                        <td className="balance">£22,000.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>09:12</td>
                        <td>DEPOSIT</td>
                        <td className="total-amount">+ £100.00</td>
                        <td className="balance">£21,900.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>09:12</td>
                        <td>INTEREST ADDED</td>
                        <td className="total-amount">+ £100.00</td>
                        <td className="balance">£21,800.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>09:12</td>
                        <td>WITHDRAWAL</td>
                        <td className="total-amount-withdraw">- £100.00</td>
                        <td className="balance">£21,800.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>09:12</td>
                        <td>DEPOSIT</td>
                        <td className="total-amount">+ £100.00</td>
                        <td className="balance">£21,900.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>09:12</td>
                        <td>DEPOSIT</td>
                        <td className="total-amount">+ £100.00</td>
                        <td className="balance">£21,900.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>09:12</td>
                        <td>DEPOSIT</td>
                        <td className="total-amount">+ £100.00</td>
                        <td className="balance">£21,900.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>09:12</td>
                        <td>DEPOSIT</td>
                        <td className="total-amount">+ £100.00</td>
                        <td className="balance">£21,900.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>09:12</td>
                        <td>DEPOSIT</td>
                        <td className="total-amount">+ £100.00</td>
                        <td className="balance">£21,900.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>09:12</td>
                        <td>DEPOSIT</td>
                        <td className="total-amount">+ £100.00</td>
                        <td className="balance">£21,900.00</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>ACCOUNT DETAIL</h2>
                <div className="product-overview-bottom">
                  <div className="bottom-top">
                    <h3>OWNER</h3>
                    <p>Tony Jones Smith</p>
                  </div>
                  <div className="bottom-down">
                    <h3>OPENING DATE</h3>
                    <p>25th January 2023</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>DONATION DETAIL </h2>
                <div className="product-overview-bottom">
                  <div className="bottom-top d-flex justify-content-between align-items-end">
                    <div>
                      <h3>MANCHESTER UNITED FC</h3>
                      <p>Started: 25th January 2023</p>
                    </div>
                    <button type="button">Raised: £540</button>
                  </div>
                  <div className="bottom-down d-flex justify-content-between align-items-end">
                    <div>
                      <h3>SUNDERLAND AFC JUNIORS </h3>
                      <p>Started: 25th January 2023</p>
                    </div>
                    <button type="button">Raised: £240</button>
                  </div>
                  <div className="bottom-down d-flex justify-content-between align-items-end">
                    <div>
                      <h3>CLUBERLY NATIONAL POT</h3>
                      <p>Started: 25th January 2023</p>
                    </div>
                    <button type="button">Raised: £890</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>ADMIN ACTIONS</h2>
                <button type="button" className="edit-product">
                  DELETE / CLOSE ACCOUNT
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
