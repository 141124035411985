import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import currencies from "./currency.json";
import {
  addandupdateblogAction,
  createproductAction,
  getcategorylistAction,
  productdetailsAction,
  productmainimagesaveAction,
  uploadproductimagesAction,
} from "../Redux/Action/AdminstratorAction";
import { useState } from "react";
import Editor from "../Component/TextEditor";
import { EditorState } from "draft-js";
import { Decryptedid } from "../Util/BcruptEncyptid";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import Accordion from "react-bootstrap/Accordion";
import DeleteComponent from "../Component/DeleteComponent";
export default function OrderView() {
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currencyse, setCurrency] = useState("USD");
  const [selectedImage, setSelectedImage] = useState();
  const [selectedDImage, setSelectedDImage] = useState([]);
  const [selectedDImageMain, setSelectedDImageMain] = useState("");
  const [options, setOptions] = useState([{ title: "Size", values: [""] }]); // State to manage options
  const [variants, setVariants] = useState([
    {
      size: "",
      color: "",
      price: "",
      quantity: "",
    },
  ]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);

  const { id } = useParams();

  var idvalue = Decryptedid(atob(id));

  useEffect(() => {
    dispatch(productdetailsAction({ id: idvalue })).then(function (data) {
      if (data.payload) {
        var Details = data.payload;
        for (const [key, value] of Object.entries(Details)) {
          if (
            key != "_id" &&
            key != "images" &&
            key != "options" &&
            key != "size" &&
            key != "mainimage"
          ) {
            formik.setFieldValue(key, value, { strict: false });
          }
          if (key == "mainimage") {
            setSelectedDImageMain(value);
          }

          if (key == "options") {
            setOptions(value);
          }
          if (key == "variants") {
            setVariants(value);
          }

          if (key == "currency") {
            setCurrency(value);
          }

          if (key == "images") {
            setSelectedDImage(value);
          }
          if (key == "size") {
            formik.setFieldValue(key, value[0], { strict: false });
          }
        }
      }
    });
  }, [idvalue]);

  useEffect(() => {
    dispatch(
      getcategorylistAction({
        perpage: 100,
        pageno: 1,
        tab: 1,
        serachkeyval: "",
      })
    );
  }, []);

  const categorylist = useSelector((state) => state.Administrator.categorylist);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",

      price: "",
      amountdonated: 0,
      quantity: "",
      category: "",
      postageprice: "",
      shippingprice: "",

      publicstatus: 0,
      images: [],
      options: [{ title: "", size: [""] }],
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      category: Yup.string().required("Category is required"),

      postageprice: Yup.string().required("Postage Price is required"),
      // shippingprice: Yup.string().required("Shipping Price is required"),
      amountdonated: Yup.string().required("Amount donated is required"),

      publicstatus: Yup.number(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      values.images = selectedDImage;
      values.mainimage = selectedDImageMain;
      values.options = options;

      values.id = idvalue;

      values.variants = variants;

      variants.includes();

      let magenicVendors = variants.filter(
        (object) =>
          (object["price"] == "" || object["price"] == undefined) &&
          (object["quantity"] == "" || object["quantity"] == undefined)
      );

      if (magenicVendors.length > 0) {
        toast.error("All price and Quantities are required");
      } else {
        dispatch(createproductAction(values)).then(function () {
          navigate("/manage-stocks");
        });
      }
    },
  });

  console.log(formik.errors, "dsfaaaaaaaaaaaaa");

  const handleAddValue = (index) => {
    // Create a copy of the options array
    const newOptions = [...options];
    // Create a copy of the values array within the specified index
    const newValues = [...newOptions[index].values];
    // Push an empty string to the copied values array
    newValues.push("");
    // Update the values array within the specified index in the copied options array
    newOptions[index] = { ...newOptions[index], values: newValues };
    // Update the state with the modified options array
    setOptions(newOptions);
  };

  const handleDeleteValue = (optionIndex, valueIndex) => {
    const newOptions = [...options];
    newOptions[optionIndex].values.splice(valueIndex, 1);
    setOptions(newOptions);
  };

  const handleCloneOption = (index) => {
    // Create a copy of the options array
    const newOptions = [...options];
    // Clone the option at the specified index
    const clonedOption = { ...newOptions[index], title: "", values: [""] }; // Set the values to an empty array
    // Insert the cloned option after the original option
    newOptions.splice(index + 1, 0, clonedOption);
    // Update the state with the modified options array
    setOptions(newOptions);
  };

  const handleDeleteCloneOption = (optionIndex) => {
    // Create a copy of the options array
    const newOptions = [...options];
    // Remove the option at the specified index
    newOptions.splice(optionIndex, 1);
    // Update the state with the modified options array
    setOptions(newOptions);
  };

  const handleOptionNameChange = (index, event) => {
    const newOptions = [...options];
    newOptions[index].title = event.target.value;
    setOptions(newOptions);
  };

  const handleOptionValueChange = (optionIndex, valueIndex, event) => {
    const newOptions = [...options];
    newOptions[optionIndex].values[valueIndex] = event.target.value;
    setOptions(newOptions);
  };

  const handlePriceChange = (index, price, size, color) => {
    const updatedVariants = [...variants];
    updatedVariants[index] = { ...updatedVariants[index], size, color, price };
    setVariants(updatedVariants);
  };

  const handleQuantityChange = (index, quantity, size, color) => {
    const updatedVariants = [...variants];
    updatedVariants[index] = {
      ...updatedVariants[index],
      quantity,
      size,
      color,
    };
    setVariants(updatedVariants);
  };

  console.log(variants, "variantsvariantsvariants");

  // Logic to handle different scenarios
  const renderVariants = () => {
    if (options[0]?.values && options[1]?.values) {
      // Both size and color are available
      return options[0]?.values?.map((size, sizeIndex) => {
        return options[1]?.values?.map((color, colorIndex) => {
          const index = sizeIndex * options[1]?.values.length + colorIndex;
          return renderVariant(size, color, index);
        });
      });
    } else if (options[0]?.values) {
      // Only size is available
      return options[0]?.values?.map((size, sizeIndex) => {
        return renderVariant(size, null, sizeIndex);
      });
    } else if (options[1]?.values) {
      // Only color is available
      return options[1]?.values?.map((color, colorIndex) => {
        return renderVariant(null, color, colorIndex);
      });
    } else {
      // Neither size nor color is available
      return null;
    }
  };

  const renderVariant = (size, color, index) => {
    const variant = {
      size,
      color,
      price: variants[index]?.price || "",
      quantity: variants[index]?.quantity || "",
    };

    return (
      <Row key={index} className="mb-2">
        <Col lg={2} className="">
          <div className="custom-text">
            {size && `${size}`}
            {size !== null && color !== null && "/"}
            {color}
          </div>
        </Col>
        <Col lg={5}>
          <Form.Label>Price</Form.Label>
          <Form.Group controlId={`price_${index}`}>
            <CurrencyInput
              id="input-example"
              name="input-name"
              placeholder="Please enter a number"
              intlConfig={{ locale: "en-US", currency: "GBP" }}
              value={variant.price}
              decimalsLimit={2}
              decimalScale={2}
              onValueChange={(value, name, values) => {
                handlePriceChange(index, value, size, color);
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={5}>
          <Form.Label>Quantity</Form.Label>
          <Form.Group controlId={`quantity_${index}`}>
            <Form.Control
              type="number"
              placeholder=""
              value={variant.quantity}
              onChange={(e) =>
                handleQuantityChange(index, e.target.value, size, color)
              }
            />
          </Form.Group>
        </Col>
      </Row>
    );
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Update Product</h2>
          </div>
          <div></div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/manage-stocks">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <form onSubmit={formik.handleSubmit}>
        <Container fluid>
          <Row>
            <Col lg={8}>
              <Row>
                <Col lg={12}>
                  <div className="customer-form-new">
                    <h2>DETAILS</h2>
                    <hr className="cmn-border" />
                    <div className="customer-form-inner mt-0 pt-0">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3 mt-4">
                            <Form.Label>Product Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              name="title"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.title}
                            />
                            {formik.submitCount > 0 && formik.errors.title && (
                              <div className="errors">
                                {formik.errors.title}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Type Here"
                              name="description"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.description}
                            />
                            {formik.submitCount > 0 &&
                              formik.errors.description && (
                                <div className="errors">
                                  {formik.errors.description}
                                </div>
                              )}
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>
                              Style Options (Add Club Mascot)
                            </Form.Label>
                            <div className="product-check">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="styleoption"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  checked={formik.values.styleoption == 1}
                                  value="1"
                                  id="flexCheckDefault"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Yes
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="styleoption"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value="0"
                                  checked={formik.values.styleoption == 0}
                                  id="flexCheckDefault"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Category</Form.Label>
                            <div className="product-check">
                              <Form.Select
                                aria-label="Default select example"
                                name="category"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.category}
                              >
                                <option>Open this select menu</option>
                                {categorylist?.alllist?.map(function (
                                  object,
                                  i
                                ) {
                                  return (
                                    <option value={object?._id}>
                                      {object?.title}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              {formik.submitCount > 0 &&
                                formik.errors.category && (
                                  <div className="errors">
                                    {formik.errors.category}
                                  </div>
                                )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Domestic Postage</Form.Label>
                            {/* <Form.Control
                              type="text"
                              placeholder="&pound;0.00"
                              name="price"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                            /> */}

                            <CurrencyInput
                              id="input-example"
                              name="postageprice"
                              placeholder="Enter an amount"
                              intlConfig={{ locale: "en-US", currency: "GBP" }}
                              value={formik.values.postageprice}
                              decimalsLimit={2}
                              decimalScale={2}
                              onBlur={formik.handleBlur}
                              onValueChange={(value, name, values) => {
                                formik.setFieldValue("postageprice", value);
                                // handlePriceChange(index, values, size, color)
                              }}
                            />

                            {formik.submitCount > 0 &&
                              formik.errors.postageprice && (
                                <div className="errors">
                                  {formik.errors.postageprice}
                                </div>
                              )}
                          </Form.Group>
                        </Col>
                        {/* <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Price</Form.Label>

                            <CurrencyInput
                              id="input-example"
                              name="price"
                              placeholder="Please enter a number"
                              intlConfig={{ locale: "en-US", currency: "GBP" }}
                              value={formik.values.price}
                              decimalsLimit={2}
                              onBlur={formik.handleBlur}
                              onValueChange={(value, name, values) => {
                                formik.setFieldValue("price", value);
                                 handlePriceChange(index, values, size, color)
                              }}
                            />

                            {formik.submitCount > 0 && formik.errors.price && (
                              <div className="errors">
                                {formik.errors.price}
                              </div>
                            )}
                          </Form.Group>
                        </Col> */}

                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>International Postage</Form.Label>

                            <Row>
                              <Col lg={2} className="pe-0">
                                <select
                                  className="w-100"
                                  id="currency"
                                  name="currency"
                                  onBlur={formik.handleBlur}
                                  onChange={(e) => {
                                    setCurrency(e.target.value);

                                    formik.setFieldValue(
                                      "currency",
                                      e.target.value,
                                      { strict: false }
                                    );
                                    formik.setFieldValue(
                                      "internationalcurrency",
                                      "",
                                      { strict: false }
                                    );
                                  }}
                                  value={formik.values.currency}
                                >
                                  {Object.keys(currencies).map(
                                    (currency, index) => {
                                      return (
                                        <option value={currency} key={index}>
                                          {currency} -{" "}
                                          {currencies[currency].name}
                                        </option>
                                      );
                                    }
                                  )}

                                  {/* Add more currency options as needed */}
                                </select>

                                {formik.submitCount > 0 &&
                                  formik.errors.currency && (
                                    <div className="errors">
                                      {formik.errors.currency}
                                    </div>
                                  )}
                              </Col>
                              <Col lg={10} className="ps-0">
                                <CurrencyInput
                                  id="input-example"
                                  placeholder="Enter an amount"
                                  name="internationalcurrency"
                                  intlConfig={{
                                    locale: "en-US",
                                    currency: currencyse,
                                  }}
                                  value={formik.values.internationalcurrency}
                                  decimalsLimit={2}
                                  decimalScale={2}
                                  onBlur={formik.handleBlur}
                                  onValueChange={(value, name, values) => {
                                    formik.setFieldValue(
                                      "internationalcurrency",
                                      value
                                    );
                                    // handlePriceChange(index, values, size, color)
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </Col>

                        {/* <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Shipping Price</Form.Label>
                          

                            <CurrencyInput
                              id="input-example"
                              name="shippingprice"
                              intlConfig={{ locale: "en-US", currency: "GBP" }}
                              value={formik.values.shippingprice}
                              decimalsLimit={2}
                              onBlur={formik.handleBlur}
                              onValueChange={(value, name, values) => {
                                formik.setFieldValue("shippingprice", value);
                                // handlePriceChange(index, values, size, color)
                              }}
                            />
                            {formik.submitCount > 0 &&
                              formik.errors.shippingprice && (
                                <div className="errors">
                                  {formik.errors.shippingprice}
                                </div>
                              )}
                          </Form.Group>
                        </Col> */}

                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>% Amount Donated to Club</Form.Label>
                            <CurrencyInput
                              suffix="%"
                              id="input-example"
                              name="amountdonated"
                              placeholder="Type Here"
                              onBlur={formik.handleBlur}
                              onValueChange={(value, name, values) => {
                                if (value == undefined) {
                                  formik.setFieldValue("amountdonated", 0);
                                } else {
                                  formik.setFieldValue("amountdonated", value);
                                }
                                // handlePriceChange(index, values, size, color)
                              }}
                            />

                            {formik.submitCount > 0 &&
                              formik.errors.amountdonated && (
                                <div className="errors">
                                  {formik.errors.amountdonated}
                                </div>
                              )}
                          </Form.Group>
                        </Col>

                        <Col lg={4}>
                          <Form.Group className="mb-3 image-upload-main">
                            <Form.Label>Main Image</Form.Label>
                            <div className="input-image-show">
                              <Form.Control
                                type="file"
                                multiple
                                placeholder="Upload Image"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  let formdata = new FormData();

                                  formdata.append("images", e.target.files[0]);

                                  dispatch(
                                    productmainimagesaveAction(formdata)
                                  ).then(function (payload) {
                                    setSelectedDImageMain(
                                      payload.payload.images
                                    );
                                  });
                                }}
                                name="coverimage"
                                value={formik.values.coverimage}
                              />

                              <p>Upload Image</p>
                            </div>
                          </Form.Group>
                          <div className="d-flex create-product-images">
                            {selectedDImageMain != "" && (
                              <img
                                className="upload-img-show"
                                src={selectedDImageMain}
                                alt="Thumb"
                                crossOrigin="anonymous"
                              />
                            )}
                          </div>
                        </Col>
                        <Col lg={4}>
                          <Form.Group className="mb-3 image-upload-main">
                            <Form.Label>Other Image</Form.Label>
                            <div className="input-image-show">
                              <Form.Control
                                type="file"
                                multiple
                                placeholder="Upload Image"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  let formdata = new FormData();

                                  for (
                                    let i = 0;
                                    i < e.target.files.length;
                                    i++
                                  ) {
                                    formdata.append(
                                      "images",
                                      e.target.files[i]
                                    );
                                  }

                                  dispatch(
                                    uploadproductimagesAction(formdata)
                                  ).then(function (payload) {
                                    setSelectedDImage(payload.payload.images);
                                  });
                                }}
                                name="coverimage"
                                value={formik.values.coverimage}
                              />

                              <p>Upload Image</p>
                            </div>
                          </Form.Group>
                          <div className="d-flex create-product-images">
                            {selectedDImage?.map(function (imageobject) {
                              return (
                                <img
                                  className="upload-img-show"
                                  src={imageobject?.title}
                                  alt="Thumb"
                                  crossOrigin="anonymous"
                                />
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col lg={12} className="mt-4">
                  <div className="customer-form-new">
                    <div className="customer-form-inner product">
                      <h2>
                        <b>Option :</b> This will include size and color variant
                        of the product
                      </h2>
                      <Accordion>
                        <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
                          {options?.map((option, index) => (
                            <Accordion.Item eventKey="0" className="mt-5">
                              <Accordion.Header className="mt-0 mb-0 ">
                                <div className="accord-heading">
                                  <span>{option.title} : </span>
                                  {option?.values?.length > 1 && (
                                    <div className="accord-options">
                                      <ul>
                                        {option?.values?.length > 0 &&
                                          option?.values?.map(
                                            (value, valueIndex) => (
                                              <li>{value}</li>
                                            )
                                          )}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                {" "}
                                <Form.Group className="mb-3">
                                  <Form.Label>Option Name</Form.Label>
                                  <Row>
                                    <Col lg={11}>
                                      <Form.Select
                                        aria-label="Default select example"
                                        value={option.title}
                                        onChange={(e) =>
                                          handleOptionNameChange(index, e)
                                        }
                                      >
                                        <option value="">
                                          Please Select option name
                                        </option>
                                        <option value="Color">Color</option>
                                        <option value="Size">Size</option>
                                      </Form.Select>
                                    </Col>
                                    <Col lg={1}>
                                      {index > 0 && (
                                        <button
                                          type="button"
                                          className="product-btn"
                                          onClick={() => {
                                            handleDeleteCloneOption(index);
                                          }}
                                        >
                                          <img
                                            src={
                                              require("../Assets/Images/delete.svg")
                                                .default
                                            }
                                          />
                                        </button>
                                      )}
                                    </Col>
                                  </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Label>Option Value</Form.Label>

                                  {option?.values?.map((value, valueIndex) => (
                                    <Row>
                                      <Col lg={11}>
                                        <Form.Control
                                          type="text"
                                          placeholder="Type Here"
                                          name="value"
                                          value={value}
                                          onChange={(e) =>
                                            handleOptionValueChange(
                                              index,
                                              valueIndex,
                                              e
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col lg={1}>
                                        {valueIndex > 0 && (
                                          <button
                                            type="button"
                                            className="product-btn"
                                            onClick={() =>
                                              handleDeleteValue(
                                                index,
                                                valueIndex
                                              )
                                            }
                                          >
                                            <img
                                              src={
                                                require("../Assets/Images/delete.svg")
                                                  .default
                                              }
                                            />
                                          </button>
                                        )}
                                      </Col>
                                    </Row>
                                  ))}

                                  <Row>
                                    <Col lg={11}>
                                      <button
                                        type="button"
                                        className="add-value-btn"
                                        onClick={() => handleAddValue(index)}
                                      >
                                        Add Another Value
                                      </button>
                                    </Col>
                                  </Row>
                                </Form.Group>
                                {/* <div className="cmn-btn text-center">
                                <button type="button">Save</button>
                              </div> */}
                                <Col lg={12}>
                                  <button
                                    type="button"
                                    className="add-option"
                                    onClick={() => handleCloneOption(index)}
                                  >
                                    <img
                                      src={
                                        require("../Assets/Images/add.svg")
                                          .default
                                      }
                                    />
                                    add another option
                                  </button>
                                </Col>
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                          <Accordion.Item
                            eventKey="1"
                            className="mt-4 mb-4"
                            alwaysOpen
                          >
                            <Accordion.Header className="mt-0 mb-0">
                              Variants
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="customer-form-inner variants">
                                <Row className="d-flex justify-content-center">
                                  <Col lg={12}>{renderVariants()}</Col>
                                </Row>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="h-100">
              <div className="product-overview-right">
                <div className="product-overview-box">
                  <h2>PUBLISH STATUS</h2>
                  <Form.Select
                    aria-label="Default select example"
                    name="publicstatus"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.publicstatus}
                  >
                    <option value="0">DRAFT</option>
                    <option value="1">PUBLISH</option>
                  </Form.Select>
                </div>
              </div>
              <div className="product-overview-right">
                <div className="product-overview-box">
                  <h2>MARK THIS PRODUCT AS FEATURED</h2>
                  <Form.Select
                    aria-label="Default select example"
                    name="featuredstatus"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.featuredstatus}
                  >
                    <option value="1">YES</option>
                    <option value="0">NO</option>
                  </Form.Select>

                  {formik.submitCount > 0 && formik.errors.featuredstatus && (
                    <div className="errors">{formik.errors.featuredstatus}</div>
                  )}

                  <div className="blog-save-btn">
                    <div className="cmn-btn">
                      <button type="submit">Update</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="product-overview-right">
                <div className="product-overview-box">
                  <h2>ADMIN ACTIONS</h2>
                  <button
                    type="button"
                    className="edit-product"
                    onClick={handleShow}
                  >
                    DELETE ITEM
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </form>
      <DeleteComponent show={show} handleClose={handleClose} />
    </Layout>
  );
}
