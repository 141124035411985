import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { useDispatch } from "react-redux";
import { addpushnotificationAction } from "../Redux/Action/AdminstratorAction";

export default function PushNotificationCreate() {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const formik=useFormik({
    initialValues:{
      title:"",
      description:"",
      device_type:"",
      date:"",
      time:"",
     
    },
    validationSchema:Yup.object().shape({
      title:Yup.string().required('Campaign Name is required'),
      description:Yup.string().required('Notification Detail is required'),
      device_type:Yup.array().required('Please select Platform required'),
      date:Yup.date(),
      time:Yup.string(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit:(values)=>{

      dispatch(addpushnotificationAction(values)).then(function(data){
        navigate('/Push-Notification-Archive') 
      })

    }
  })



  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Push Notificiations</h2>
        </div>
        <hr />
      </div>

      <Container fluid className="back-white">
        <div className="inner-tab-cmn">
          <Link className="" to="/PushNotifications">
            Members
          </Link>
          <Link className="" to="/Push-Notification-Archive">
            Archive
          </Link>
          <Link className="active" to="/Push-Notification-Create">
            Create New
          </Link>
        </div>
      </Container>
      <form onSubmit={formik.handleSubmit}>

     
      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form-new">
                  <h2>CREATE A NEW PUSH NOTIFICATION</h2>
                  <hr className="cmn-border" />
                  <div className="customer-form-inner">
                    <Row>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Campaign Name</Form.Label>
                          <Form.Control type="text" placeholder="Type Here" 
                          value={formik.values.title} 
                            name="title" onBlur={formik.handleBlur}
                           onChange={formik.handleChange}

                      />
                       {formik.errors.title && <div className="errors">{formik.errors.title}</div>}
                        </Form.Group>
                      </Col>

                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Notification Detail</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Type Here"
                            value={formik.values.description} 
                            name="description" onBlur={formik.handleBlur}
                           onChange={formik.handleChange}

                      />
                       {formik.errors.description && <div className="errors">{formik.errors.description}</div>}
                        </Form.Group>
                      </Col>

                      <Col lg={12}>
                        <Form.Group className="mb-3 pb-3">
                          <Form.Label>Send to</Form.Label>
                          <div className="create-mail-checks">
                            <div className="check-box-inner">
                              <label>iOS</label>
                              <Form>
                                {["checkbox"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                     
                                      type={type}
                                      id={`inline-${type}-1`}
                                      value="2"
                                    name="device_type"
                                     onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                            />
                                  </div>
                                ))}
                              </Form>
                            </div>

                            <div className="check-box-inner">
                              <label>Android</label>
                              <Form>
                                {["checkbox"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                   
                                      type={type}
                                      id={`inline-${type}-1`}
                                      value="1" 
                                    name="device_type" onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                        </Form.Group>
                        <hr className="cmn-border" />
                        {formik.errors.device_type && <div className="errors">{formik.errors.device_type}</div>}
                      </Col>
                      <Col lg={3}>
                        <Form.Group className="mt-4 ">
                          <Form.Label>Scheduled Date & Time</Form.Label>
                          <Form.Control type="date" placeholder="Type Here" 
                        name="date" onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}

                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group className="mt-4 ">
                          <Form.Label>Scheduled Date & Time</Form.Label>
                          <Form.Control type="time" placeholder="Type Here"
                             name="time" onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                           />
                        </Form.Group>
                      </Col>
                      <Col
                        lg={6}
                        className="d-flex justify-content-end align-items-end"
                      >
                        <div className="cmn-btn">
                          <button type="button">SCHEDULE</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4} className="h-100">
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>PUBLISH STATUS</h2>
                <Form.Select aria-label="Default select example">
                  <option value="0">Draft</option>
                  <option value="1">Publish</option>
                </Form.Select>

                <div className="blog-save-btn">
                  <div className="cmn-btn">
                    <button type="submit">SAVE / SEND</button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      </form>
    </Layout>
  );
}
