import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AddadmincreateAction } from "../Redux/Action/Authaction";
import { Decryptedid } from "../Util/BcruptEncyptid";
import { ProfiledetailsAction } from "../Redux/Action/AdminstratorAction";
export default function EditAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [idval, setIdval] = useState("");
  const { id } = useParams();

  var idvalue = Decryptedid(atob(id));

  useEffect(() => {
    dispatch(ProfiledetailsAction(idvalue)).then(function (data) {
      if (data.payload) {
        var Details = data.payload;
        for (const [key, value] of Object.entries(Details)) {
          formik.setFieldValue(key, value, { strict: false });
        }
      }
    });
  }, [idvalue]);

  const admindetails = useSelector((state) => state.Administrator.admindetails);

  const formik = useFormik({
    initialValues: {
      name: admindetails?.name || "",
      email: admindetails?.email || "",
      password: "",
      mobile: admindetails?.mobile || "",
      role: admindetails?.role || "",
      view_financial: admindetails?.view_financial || 0,
      view_customer_sensitive: admindetails?.view_customer_sensitive || 0,
      edit_data: admindetails?.edit_data || 0,
      dashboard: admindetails?.dashboard || 0,
      products: admindetails?.products || 0,
      members: admindetails?.members || 0,
      clubs: admindetails?.clubs || 0,
      transactions: admindetails?.transactions || 0,
      adminstrators: admindetails?.adminstrators || 0,
      blogs: admindetails?.blogs || 0,
      mailinglist: admindetails?.mailinglist || 0,
      pushnotification: admindetails?.pushnotification || 0,
      files: admindetails?.files || 0,
      support: admindetails?.support || 0,
      errorlogs: admindetails?.errorlogs || 0,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
      mobile: Yup.number().required("Mobile is required"),
      role: Yup.string().required("Role is required"),
      view_financial: Yup.string(),
      view_customer_sensitive: Yup.string(),
      edit_data: Yup.string(),
      dashboard: Yup.string(),
      products: Yup.string(),
      members: Yup.string(),
      clubs: Yup.string(),
      transactions: Yup.string(),
      adminstrators: Yup.string(),
      blogs: Yup.string(),
      mailinglist: Yup.string(),
      pushnotification: Yup.string(),
      files: Yup.string(),
      support: Yup.string(),
      errorlogs: Yup.string(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      values.id = idvalue;
      dispatch(AddadmincreateAction(values)).then(function () {
        navigate("/Administrators");
      });
    },
  });

  console.log(formik.errors);
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Administrator Details</h2>
            <p>ADMIN</p>
          </div>
          <div>
            <div className="date-created">
              <h4>
                CREATED: <span>01/02/2023</span>
              </h4>
              <h4>
                LAST ACTIVE: <span>01/02/2023</span>
              </h4>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <Container fluid>
          <div className="product-cmn-tab">
            <Row>
              <Col lg={4}>
                <div className="product-tab-left">
                  <Link to="/Administrators">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="42"
                      viewBox="0 0 40 42"
                      fill="none"
                    >
                      <path
                        d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                        fill="#40413A"
                      />
                    </svg>{" "}
                    Back to previous page
                  </Link>
                </div>
              </Col>
              <Col
                lg={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <button type="submit">SAVE</button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container fluid>
          <Row>
            <Col lg={8}>
              <Row>
                <Col lg={12}>
                  <div className="customer-form-new">
                    <h2>BASIC DETAILS</h2>
                    <hr className="cmn-border" />
                    <div className="customer-form-inner">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Admin Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="name"
                              value={formik.values.name}
                            />
                            {formik.submitCount > 0 && formik.errors.name && (
                              <div className="errors">{formik.errors.name}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Type Here"
                              name="email"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                            {formik.submitCount > 0 && formik.errors.email && (
                              <div className="errors">
                                {formik.errors.email}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Mobile Phone</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="+44"
                              name="mobile"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.mobile}
                            />
                            {formik.submitCount > 0 && formik.errors.mobile && (
                              <div className="errors">
                                {formik.errors.mobile}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Role</Form.Label>

                            <Form.Select
                              aria-label="Default select example"
                              name="role"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.role}
                            >
                              <option value="">Please select Role</option>
                              <option value="0"> Super Admin</option>
                              <option value="1">Admin</option>
                              <option value="2">Developer</option>
                            </Form.Select>

                            {formik.submitCount > 0 && formik.errors.role && (
                              <div className="errors">{formik.errors.role}</div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4 mb-4">
                <Col lg={12}>
                  <div className="customer-form-new">
                    <h2>ACCESS & PERMISSIONS</h2>
                    <hr className="cmn-border" />
                    <div className="access-permission-main">
                      <Row>
                        <Col lg={12}>
                          <div className="access-select-box mt-4 pt-2">
                            <h3>GENERAL</h3>
                            <div className="d-flex inner">
                              <h3>YES</h3>
                              <h3>No</h3>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>VIEW FINANCIAL DATA</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="view_financial"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={
                                        formik.values.view_financial == 1
                                      }
                                    />
                                    <Form.Check
                                      inline
                                      name="view_financial"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={
                                        formik.values.view_financial == 0
                                      }
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>VIEW CUSTOMER SENSITIVE DATA</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="view_customer_sensitive"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={
                                        formik.values.view_customer_sensitive ==
                                        1
                                      }
                                    />
                                    <Form.Check
                                      inline
                                      name="view_customer_sensitive"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={
                                        formik.values.view_customer_sensitive ==
                                        0
                                      }
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>EDIT DATA</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="edit_data"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={formik.values.edit_data == 1}
                                    />
                                    <Form.Check
                                      inline
                                      name="edit_data"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={formik.values.edit_data == 0}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>

                          <div className="access-select-box mt-4 pt-4">
                            <h3>PAGES</h3>
                            <div className="d-flex inner">
                              <h3></h3>
                              <h3></h3>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>DASHBOARD</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="dashboard"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={formik.values.dashboard == 1}
                                    />
                                    <Form.Check
                                      inline
                                      name="dashboard"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={formik.values.dashboard == 0}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>PRODUCTS</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="products"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={formik.values.products == 1}
                                    />
                                    <Form.Check
                                      inline
                                      name="products"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={formik.values.products == 0}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>MEMBERS</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="members"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={formik.values.members == 1}
                                    />
                                    <Form.Check
                                      inline
                                      name="members"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={formik.values.members == 0}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>CLUBS</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="clubs"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={formik.values.clubs == 1}
                                    />
                                    <Form.Check
                                      inline
                                      name="clubs"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={formik.values.clubs == 0}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>TRANSACTIONS</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="transactions"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={formik.values.transactions == 1}
                                    />
                                    <Form.Check
                                      inline
                                      name="transactions"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={formik.values.transactions == 0}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>ADMINISTRATORS</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="adminstrators"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={formik.values.adminstrators == 1}
                                    />
                                    <Form.Check
                                      inline
                                      name="adminstrators"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={formik.values.adminstrators == 0}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>BLOG</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="blogs"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={formik.values.blogs == 1}
                                    />
                                    <Form.Check
                                      inline
                                      name="blogs"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={formik.values.blogs == 0}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>MAILING LIST</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="mailinglist"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={formik.values.mailinglist == 1}
                                    />
                                    <Form.Check
                                      inline
                                      name="mailinglist"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={formik.values.mailinglist == 0}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>PUSH NOTIFICATIONS</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="pushnotification"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={
                                        formik.values.pushnotification == 1
                                      }
                                    />
                                    <Form.Check
                                      inline
                                      name="pushnotification"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={
                                        formik.values.pushnotification == 0
                                      }
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>FILES</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="files"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={formik.values.files == 1}
                                    />
                                    <Form.Check
                                      inline
                                      name="files"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={formik.values.files == 0}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box">
                            <p>SUPPORT</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="support"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={formik.values.support == 1}
                                    />
                                    <Form.Check
                                      inline
                                      name="support"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={formik.values.support == 0}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                          <div className="access-select-box mb-4">
                            <p>ERROR LOGS</p>
                            <div className="d-flex inner">
                              <Form>
                                {["radio"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="errorlogs"
                                      type={type}
                                      value="1"
                                      onChange={formik.handleChange}
                                      id={`inline-${type}-1`}
                                      checked={formik.values.errorlogs == 1}
                                    />
                                    <Form.Check
                                      inline
                                      name="errorlogs"
                                      value="0"
                                      onChange={formik.handleChange}
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={formik.values.errorlogs == 0}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="h-100">
              <div className="product-actions">
                <h2>ACTIVITY</h2>
                <hr />
                <div className="product-action-inner">
                  <h3>
                    [Admin Name] made updates to{" "}
                    <Link to="">Support Ticket 0123</Link>{" "}
                  </h3>
                  <p>
                    01/01/2023 <span>13:32</span>
                  </p>
                </div>
                <div className="product-action-inner">
                  <h3>
                    [Admin Name] made updates to{" "}
                    <Link to="">Support Ticket 0123</Link>{" "}
                  </h3>
                  <p>
                    01/01/2023 <span>13:32</span>
                  </p>
                </div>
                <div className="product-action-inner">
                  <h3>
                    [Admin Name] made updates to{" "}
                    <Link to="">Support Ticket 0123</Link>{" "}
                  </h3>
                  <p>
                    01/01/2023 <span>13:32</span>
                  </p>
                </div>
                <div className="product-action-inner">
                  <h3>
                    [Admin Name] made updates to{" "}
                    <Link to="">Support Ticket 0123</Link>{" "}
                  </h3>
                  <p>
                    01/01/2023 <span>13:32</span>
                  </p>
                </div>
                <div className="product-action-inner">
                  <h3>
                    [Admin Name] made updates to{" "}
                    <Link to="">Support Ticket 0123</Link>{" "}
                  </h3>
                  <p>
                    01/01/2023 <span>13:32</span>
                  </p>
                </div>
                <div className="product-action-inner">
                  <h3>
                    [Admin Name] made updates to{" "}
                    <Link to="">Support Ticket 0123</Link>{" "}
                  </h3>
                  <p>
                    01/01/2023 <span>13:32</span>
                  </p>
                </div>
                <div className="product-action-inner">
                  <h3>
                    [Admin Name] made updates to{" "}
                    <Link to="">Support Ticket 0123</Link>{" "}
                  </h3>
                  <p>
                    01/01/2023 <span>13:32</span>
                  </p>
                </div>
                <div className="product-action-inner">
                  <h3>
                    [Admin Name] made updates to{" "}
                    <Link to="">Support Ticket 0123</Link>{" "}
                  </h3>
                  <p>
                    01/01/2023 <span>13:32</span>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </Layout>
  );
}
