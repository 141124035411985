import moment from "moment";
import React from "react";
import { Table } from "react-bootstrap";
import { Encryptedid } from "../Util/BcruptEncyptid";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";

export default function MemberlistsComponent({
  memberlists,
  paginationevent,
  uniqueid,
}) {
  const navigate = useNavigate();
  const Memberetails = (id) => {
    const bcrypt = Encryptedid(id);
    navigate("/Customer-Profile-Overview/" + bcrypt);
  };

  uniqueid = uniqueid >= 10 ? uniqueid + 1 : uniqueid;
  return (
    <>
      <div class="table-responsive main">
        <Table size="sm" className="table-cmn">
          <thead>
            <tr>
              <th>#</th>
              <th>JOINED</th>
              <th>NAME</th>
              <th>CITY</th>
              <th>BANK </th>
              <th>PRODUCTS</th>
              <th>CHALLENGES</th>
              <th>DEPOSITED</th>
              <th>EARNED</th>
              <th>RAISED TOT.</th>
              <th>LAST ACTIVE</th>
            </tr>
          </thead>
          <tbody>
            {memberlists?.userlists?.map(function (memberobject, i) {
              return (
                <tr key={i}>
                  <td>{uniqueid++}</td>
                  <td>
                    {moment(memberobject?.createdAt).format("DD/MM/YYYY")}
                    <br />
                    <span className="time">
                      {" "}
                      {moment(memberobject?.createdAt).format("HH:mm")}
                    </span>
                  </td>
                  <td>
                    <div
                      onClick={() => {
                        Memberetails(memberobject?._id);
                      }}
                    >
                      {memberobject?.firstname + " " + memberobject?.surname}
                    </div>
                  </td>
                  <td>
                    {" "}
                    {memberobject?.city == "" ? "---" : memberobject?.city}
                  </td>
                  <td>{memberobject?.bankstatus == 0 ? "NO" : "Yes"}</td>
                  <td>---</td>
                  <td>---</td>
                  <td>---</td>
                  <td>---</td>
                  <td>---</td>
                  <td>
                    {moment(memberobject?.updatedAt).format("DD/MM/YYYY")}
                    <br />
                    <span className="time">
                      {" "}
                      {moment(memberobject?.updatedAt).format("HH:mm")}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Pagination
        totalpagination={memberlists?.totalpagination}
        paginationevent={paginationevent}
      />
    </>
  );
}
