import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid } from "../Util/BcruptEncyptid";
import {
  accountsuspendedAction,
  deleteAdminuserAction,
  memberprofiledetailsAction,
  updatememberprofileAction,
  uploadDocumentAction,
} from "../Redux/Action/AdminstratorAction";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import DeleteComponent from "../Component/DeleteComponent";
import Membersuspended from "../Component/Membersuspended";
import { resetpasswordlinkAction } from "../Redux/Action/Authaction";
import Documentuplomodal from "../Component/Documentuplomodal";

export default function CustomerProfileOverview() {
  const [documentshows, setShownew] = useState(false);

  const documentclose = () => setShownew(false);
  const handleShownew = () => setShownew(true);

  const [show, setShow] = useState(false);
  const [showsuspended, setShowsuspended] = useState(false);
  const [description, setDescription] = useState("");
  const handleClose = () => setShow(false);

  const handleClosesus = () => setShowsuspended(false);
  const handleShow = () => setShow(true);
  const { id } = useParams();

  var idvalue = Decryptedid(atob(id));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      memberprofiledetailsAction({
        _id: idvalue,
      })
    ).then(function (data) {
      if (data.payload) {
        var Details = data.payload;
        for (const [key, value] of Object.entries(Details)) {
          if (key == "datebirth") {
            formik.setFieldValue(
              "datebirth",
              moment(value).format("YYYY-MM-DD"),
              { strict: false }
            );
          } else {
            formik.setFieldValue(key, value, { strict: false });
          }
        }
      }
    });
  }, [idvalue]);

  const memberdetails = useSelector(
    (state) => state.Administrator.memberdetails
  );

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstname: memberdetails?.firstname || "",
      surname: memberdetails?.surname || "",
      email: memberdetails?.email || "",
      phone: memberdetails?.phone || "",
      address1: memberdetails?.address1 || "",
      city: memberdetails?.city || "",
      state: memberdetails?.state || "",
      postcode: memberdetails?.postcode || "",
      datebirth: memberdetails?.datebirth || "",
    },
    validationSchema: Yup.object().shape({
      surname: Yup.string().required("Sur Name is required"),
      datebirth: Yup.string().required("Date of birth is required"),

      email: Yup.string()
        .email("Please enter a valid email")
        .required("Email is required"),
      phone: Yup.number().required("Phone is required"),
      address1: Yup.string().required("Address line is required"),
      city: Yup.string().required("City  is required"),
      state: Yup.string().required("State  is required"),
      postcode: Yup.string().required("Postcode  is required"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      values.id = idvalue;
      dispatch(updatememberprofileAction(values)).then(function () {
        navigate("/Members");
      });
    },
  });

  const deleteadmin = () => {
    dispatch(
      deleteAdminuserAction({
        id: idvalue,
        type: 1,
      })
    ).then(function () {
      setShow(false);
      navigate("/Members");
    });
  };

  const suspend = () => {
    setShowsuspended(false);
    dispatch(
      accountsuspendedAction({
        id: idvalue,
        accountstatus: memberdetails.accountstatus == 1 ? 0 : 1,
      })
    );
  };

  // accountsuspendedAction

  const submitdocument = (values) => {
    var appendform = new FormData();
    appendform.append("title", values.title);
    appendform.append("filename", values.filename);
    appendform.append("userid", idvalue);

    dispatch(uploadDocumentAction(appendform));
    setShownew(false);
  };

  const getFileExtension = (url) => {
    // Split the URL by '.'
    const parts = url.split(".");
    // Get the last part which should be the extension
    return parts[parts.length - 1].toLowerCase();
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>
              <img
                src={
                  memberdetails?.profilepic == undefined
                    ? require("../Assets/Images/dummy.svg").default
                    : process.env.REACT_APP_IMAGE_URL +
                      "profilepic/" +
                      memberdetails?.profilepic
                }
              />
              {memberdetails?.firstname + " " + memberdetails?.surname}
            </h2>
          </div>
          <div>
            <div className="date-created">
              <h4>
                CREATED:{" "}
                <span>
                  {" "}
                  {moment(memberdetails?.createdAt).format("DD/MM/YYYY")}
                </span>
              </h4>
              <h4>
                LAST ACTIVE:{" "}
                <span>
                  {moment(memberdetails?.updatedAt).format("DD/MM/YYYY")}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Members">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col lg={8}>
              <div className="product-tab-cmn-btns">
                <Link
                  className="active buttons"
                  to="/Customer-Profile-Overview"
                >
                  Overview
                </Link>

                <Link
                  className="buttons"
                  to="/Customer-Profile-product-Overview"
                >
                  Products
                </Link>
                <Link className="buttons" to="/Customer-Profile-Transactions">
                  Transactions
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12}>
                  <div className="customer-form">
                    <h2>MEMBER INFORMATION</h2>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Tony Jones Smith"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="firstname"
                            value={formik.values.firstname}
                          />
                          {formik.submitCount > 0 &&
                            formik.errors.firstname && (
                              <div className="errors">
                                {formik.errors.firstname}
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Sur Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Tony Jones Smith"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="surname"
                            value={formik.values.surname}
                          />
                          {formik.submitCount > 0 && formik.errors.surname && (
                            <div className="errors">
                              {formik.errors.surname}
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="tonyjones@manchesterunited.com"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="email"
                            value={formik.values.email}
                          />
                          {formik.submitCount > 0 && formik.errors.email && (
                            <div className="errors">{formik.errors.email}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>DOB</Form.Label>

                          <Form.Control
                            type="date"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              const [year, month, day] =
                                e.target.value.split("-");
                              console.log(`${day}-${month}-${year}`);
                              formik.setFieldValue(
                                "datebirth",
                                `${year}-${month}-${day}`,
                                { strict: false }
                              );
                            }}
                            name="datebirth"
                            value={formik.values.datebirth}
                          />
                          {formik.submitCount > 0 &&
                            formik.errors.datebirth && (
                              <div className="errors">
                                {formik.errors.datebirth}
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="+442038806050"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="phone"
                            value={formik.values.phone}
                          />
                          {formik.submitCount > 0 && formik.errors.phone && (
                            <div className="errors">{formik.errors.phone}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            className="mb-2"
                            type="text"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="address1"
                            value={formik.values.address1}
                          />
                          {formik.submitCount > 0 && formik.errors.address1 && (
                            <div className="errors">
                              {formik.errors.address1}
                            </div>
                          )}
                          <Form.Control
                            type="text"
                            className="mb-2"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="city"
                            value={formik.values.city}
                          />
                          {formik.submitCount > 0 && formik.errors.city && (
                            <div className="errors">{formik.errors.city}</div>
                          )}
                          <Form.Control
                            type="text"
                            className="mb-2"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="state"
                            value={formik.values.state}
                          />
                          {formik.submitCount > 0 && formik.errors.state && (
                            <div className="errors">{formik.errors.state}</div>
                          )}
                          <Form.Control
                            type="text"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="postcode"
                            value={formik.values.postcode}
                          />
                          {formik.submitCount > 0 && formik.errors.postcode && (
                            <div className="errors">
                              {formik.errors.postcode}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col
                        lg={6}
                        className="d-flex justify-content-end align-items-end"
                      >
                        <div class="delete-btn">
                          <button type="submit">SUBMIT</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </form>
            <Row>
              <Col lg={12}>
                <div className="profile-products-sec">
                  <h2>ACTIVE PRODUCTS </h2>
                  <hr />
                  <div className="product-main-box">
                    <Row>
                      <Col lg={6} className="d-flex align-items-center">
                        <div className="product-inner">
                          <h3>CLUBERLY SAVINGS ISA </h3>
                          <p>Balance: £10,987.00</p>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex justify-content-end">
                        <div className="product-inner-right">
                          <button className="active-btn">Active</button>
                          <p>Opened: Monday 6th March 2023</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div className="product-main-box">
                    <Row>
                      <Col lg={6} className="d-flex align-items-center">
                        <div className="product-inner">
                          <h3>CLUBERLY SAVINGS ISA </h3>
                          <p>Balance: £0.00</p>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex justify-content-end">
                        <div className="product-inner-right">
                          <button className="closed-btn">Closed</button>
                          <p>Closed: Monday 6th March 2023</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div className="product-main-box">
                    <Row>
                      <Col lg={6} className="d-flex align-items-center">
                        <div className="product-inner">
                          <h3>CLUBERLY SAVINGS ISA </h3>
                          <p>Balance: £0.00</p>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex justify-content-end">
                        <div className="product-inner-right">
                          <button className="closed-btn">Closed</button>
                          <p>Closed: Monday 6th March 2023</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="profile-products-sec">
                  <h2>ADMIN NOTES </h2>
                  <hr />
                  <div className="admin-box-main">
                    {memberdetails?.adminnotes?.map(function (object, i) {
                      return (
                        <>
                          <div className="admin-box-inner">
                            <div className="admin-box-top">
                              <h3>
                                {object?.addedby?.name}{" "}
                                <span>
                                  {moment(object?.adddate).format("DD/MM/YYYY")}
                                </span>{" "}
                                <span>
                                  {moment(object?.adddate).format("HH:mm")}
                                </span>
                              </h3>
                            </div>
                            <p style={{ whiteSpace: "pre-line" }}>
                              {object?.description}
                            </p>
                          </div>
                          <hr />
                        </>
                      );
                    })}

                    <div className="admin-box-inner">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          value={description}
                          rows={3}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          placeholder="Type Here"
                        />
                        <Col
                          lg={6}
                          className="d-flex justify-content-end align-items-end mt-3"
                        >
                          <div class="delete-btn">
                            <button
                              type="submit"
                              onClick={() => {
                                dispatch(
                                  updatememberprofileAction({
                                    description: description,
                                    id: idvalue,
                                    addedby: localStorage.getItem("userid"),
                                  })
                                );
                                setDescription("");
                              }}
                            >
                              SUBMIT
                            </button>
                          </div>
                        </Col>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>DONATING TO </h2>
                <div className="product-overview-bottom">
                  <div className="bottom-top d-flex justify-content-between align-items-end">
                    <div>
                      <h3>MANCHESTER UNITED FC</h3>
                      <p>Started: 25th January 2023</p>
                    </div>
                    <button type="button">Raised: £540</button>
                  </div>
                  <div className="bottom-down d-flex justify-content-between align-items-end">
                    <div>
                      <h3>SUNDERLAND AFC JUNIORS </h3>
                      <p>Started: 25th January 2023</p>
                    </div>
                    <button type="button">Raised: £240</button>
                  </div>
                  <div className="bottom-down d-flex justify-content-between align-items-end">
                    <div>
                      <h3>CLUBERLY NATIONAL POT</h3>
                      <p>Started: 25th January 2023</p>
                    </div>
                    <button type="button">Raised: £890</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>ADMIN ACTIONS</h2>
                <button
                  type="button"
                  className="edit-product"
                  onClick={() => {
                    dispatch(
                      resetpasswordlinkAction({ _id: memberdetails?._id })
                    );
                  }}
                >
                  RESET PASSWORD
                </button>
                <button
                  type="button"
                  className="edit-product"
                  onClick={setShowsuspended}
                >
                  {memberdetails?.accountstatus == 1
                    ? "SUSPEND ACCOUNT"
                    : "ACTIVATE ACCOUNT"}
                </button>
                <button
                  type="button"
                  className="edit-product"
                  onClick={handleShow}
                >
                  DELETE / CLOSE ACCOUNT
                </button>
              </div>
            </div>

            <div className="product-actions">
              <h2>ACTIVITY</h2>
              <hr />
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
            </div>

            <div className="product-actions">
              <div className="d-flex justify-content-between align-items-center">
                <h2>DOCUMENTS</h2>
                <button className="add-btn" onClick={handleShownew}>
                  ADD
                </button>
              </div>
              <hr />
              {memberdetails?.documents?.map(function (filepbject, i) {
                return (
                  <div className="product-action-inner">
                    <h3>{filepbject?.title} </h3>
                    <p>
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_IMAGE_URL + filepbject?.filename
                        }
                      >
                        {getFileExtension(filepbject?.filename).toUpperCase()}
                      </a>
                    </p>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} className="delete-popup">
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <DeleteComponent
        show={show}
        handleClose={handleClose}
        type={"customer"}
        Id={idvalue}
        deleteadmin={deleteadmin}
      />
      <Membersuspended
        show={showsuspended}
        handleClose={handleClosesus}
        type={"customer"}
        Id={idvalue}
        deleteadmin={suspend}
      />

      <Documentuplomodal
        documentshows={documentshows}
        documentclose={documentclose}
        submitdocument={submitdocument}
      />
    </Layout>
  );
}
