import { createSlice } from "@reduxjs/toolkit";
import { AdminlistAction, ClubaccountsuspendedAction, ProfiledetailsAction, UserlistAction, accountsuspendedAction, addandupdateblogAction, addandupdateclubAction, blogdetailsAction, categoryandsubcategorylistAction, clubdetailsAction, dashBoardAdminAction, getbloglistAction, getcategorylistAction, getclublistAction, getexportreportlistdataAction, getproductlistAction, listnotificationAction, mailallowmemberlistAction, memberprofiledetailsAction, orderlistAction, playerlistAction, pushnotificationallowmemberlistAction, reportlistAction, updatememberprofileAction, uploadDocumentAction, uploadDocumentclubAction } from "../Action/AdminstratorAction";
// Define an initial state
const initialState = {
    Adminlist: null,
    loading: 'idle',
    error: null,
    admindetails:"",
    memberdetails:"",
    clubdetails:"",
    memberlists:[],
    getclublists:[],
    getbloglists:[],
    dashboard:{},
    blogdetails:"",
    categorysub:[],
    notificationlist:[],
    notificationallowuserlist:[],
    mailinglist:[],
    productlist:[],
    categorylist:[],
    orderlist:[],
    playerlist:[],
    reportlist:[],
    expreportlist:[]
  };



const AdministratorSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      // You can include synchronous actions here if needed
    },
    extraReducers: (builder) => {
      builder
      .addCase(AdminlistAction.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(AdminlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.Adminlist = action.payload;
      }).
      addCase(ProfiledetailsAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.admindetails = action.payload;
      })
      
      .addCase(UserlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberlists = action.payload;
      })
      .addCase(memberprofiledetailsAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberdetails = action.payload;
      })

      .addCase(orderlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.orderlist = action.payload;
      })

      .addCase(playerlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.playerlist = action.payload;
      })
      
      

      .addCase(addandupdateclubAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.clubdetails = action.payload;
      })
      
      .addCase(reportlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.reportlist = action.payload;
      })
      

      .addCase(getexportreportlistdataAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.expreportlist = action.payload;
      })
      
      
      .
      addCase(accountsuspendedAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberdetails = action.payload;
      })
      .addCase(getclublistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.getclublists = action.payload;
      })
      .addCase(clubdetailsAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.clubdetails = action.payload;
      })
      .addCase(ClubaccountsuspendedAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.clubdetails = action.payload;
      })
      .addCase(updatememberprofileAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberdetails = action.payload;
      })
      .addCase(uploadDocumentAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberdetails = action.payload;
      })
      
      .addCase(uploadDocumentclubAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.clubdetails = action.payload;
      })
      
      .addCase(AdminlistAction.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(categoryandsubcategorylistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
     
        state.categorysub = action.payload;
      })
      
      .addCase(mailallowmemberlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
     
        state.mailinglist = action.payload;
      })
      
   
      
      

      .addCase(dashBoardAdminAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.dashboard = action.payload;
      })

      

      .addCase(listnotificationAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.notificationlist = action.payload;
      })
      .addCase(pushnotificationallowmemberlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.notificationallowuserlist = action.payload;
      })


      
        //blogs section
        .addCase(getbloglistAction.fulfilled, (state, action) => {
          state.loading = 'fulfilled';
          state.getbloglists = action.payload;
        })
          .addCase(blogdetailsAction.fulfilled, (state, action) => {
          state.loading = 'fulfilled';
          state.blogdetails = action.payload;
        })
        
        .addCase(addandupdateblogAction.fulfilled, (state, action) => {
          state.loading = 'fulfilled';
          state.blogdetails = action.payload;
        })
        
        
      
              //blogs section



              //product

              .addCase(getproductlistAction.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.productlist = action.payload;
              })

              //category
              
              .addCase(getcategorylistAction.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.categorylist = action.payload;
              })
              
              
    },
  });
  
  export default AdministratorSlice.reducer;