import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { useSelector } from "react-redux";

const MyEditor = ({ handledescription, desc }) => {
  // const data = useSelector((state) => state.routesdata.databyid);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText(""))
  );
  const [text, setText] = useState("");

  // const onEditorStateChange = (editorStateval) => {
  //   setEditorState(editorStateval);

  //   // console.log( draftToHtml(convertToRaw(editorStateval.getCurrentContent())),"editorStatevaleditorStatevaleditorStatevaleditorStatevaleditorStateval")

  //   const { blocks } = draftToHtml(
  //     convertToRaw(editorStateval.getCurrentContent())
  //   );
  //   let text = blocks
  //   // .reduce((acc, item) => {
  //   //   acc = acc + item.text;
  //   //   return acc;
  //   // }, "");
  //   setText(text);
  // };

  const onEditorStateChange = (editorStateval) => {
    setEditorState(editorStateval);

    const html = draftToHtml(convertToRaw(editorStateval.getCurrentContent()));
    setText(html);
  };

  return (
    <>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions: [
            { text: "APPLE", value: "apple" },
            { text: "BANANA", value: "banana", url: "banana" },
            { text: "CHERRY", value: "cherry", url: "cherry" },
            { text: "DURIAN", value: "durian", url: "durian" },
            { text: "EGGFRUIT", value: "eggfruit", url: "eggfruit" },
            { text: "FIG", value: "fig", url: "fig" },
            { text: "GRAPEFRUIT", value: "grapefruit", url: "grapefruit" },
            { text: "HONEYDEW", value: "honeydew", url: "honeydew" },
          ],
        }}
      />
      {/* <div style={{ height: "80px", overflow: "auto" }}>{text}</div> */}
    </>
  );
};

export default MyEditor;
