import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid } from "../Util/BcruptEncyptid";
import {
  accountsuspendedAction,
  deleteAdminuserAction,
  memberprofiledetailsAction,
  orderdetailsAction,
  updatememberprofileAction,
  updatetheorderdetailsAction,
  uploadDocumentAction,
} from "../Redux/Action/AdminstratorAction";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import DeleteComponent from "../Component/DeleteComponent";
import Membersuspended from "../Component/Membersuspended";
import { resetpasswordlinkAction } from "../Redux/Action/Authaction";
import Documentuplomodal from "../Component/Documentuplomodal";
import { toast } from "react-toastify";

export default function OrderDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [orderdetails, setOrderdetails] = useState({});
  const [trackingnumber, settrackingnumber] = useState("");
  const [status, setStatus] = useState("");

  var idvalue = Decryptedid(atob(id));

  useEffect(() => {
    dispatch(
      orderdetailsAction({
        id: idvalue,
      })
    ).then(function (data) {
      if (data.payload) {
        var Details = data.payload;

        setStatus(Details.status);
        settrackingnumber(Details.trackingnumber);
        setOrderdetails(Details);
      }
    });
  }, [idvalue]);

  const submitform = () => {
    if (status == 1) {
      if (trackingnumber != "" && trackingnumber != undefined) {
        dispatch(
          updatetheorderdetailsAction({
            status: status,
            orderid: idvalue,
            trackingnumber: trackingnumber,
          })
        ).then(function () {
          dispatch(
            orderdetailsAction({
              id: idvalue,
              orderid: idvalue,
            })
          ).then(function (data) {
            if (data.payload) {
              var Details = data.payload;
              setStatus(Details.status);
              setOrderdetails(Details);
              settrackingnumber(Details.trackingnumber);
            }
          });
        });

        toast.success("The status has changed.");
      } else {
        toast.error("Tracking number is required.");
      }
    } else {
      dispatch(
        updatetheorderdetailsAction({
          status: status,
          orderid: idvalue,
          trackingnumber: trackingnumber,
        })
      ).then(function () {
        dispatch(
          orderdetailsAction({
            id: idvalue,
          })
        ).then(function (data) {
          if (data.payload) {
            var Details = data.payload;
            setStatus(Details.status);
            settrackingnumber(Details.trackingnumber);
            setOrderdetails(Details);
          }
        });
        toast.success("The status has changed.");
      });
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Order Details</h2>
            <p>ORDER ID {orderdetails?.order_id}</p>
          </div>
        </div>
        <hr />
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/manage-orders">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form">
                  <h2>ORDER INFORMATION</h2>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Lorem Ipsum"
                          readOnly
                          value={orderdetails?.productdetails?.title}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Lorem Ipsum"
                          readOnly
                          value={orderdetails?.quantity}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={orderdetails?.price?.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "GBP",
                            }
                          )}
                          readOnly
                          value={orderdetails?.price}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Variant</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Lorem Ipsum"
                          value={orderdetails?.variants}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Club Sponser</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Lorem Ipsum"
                          value=""
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Shipping Details</Form.Label>
                        <Row>
                          <Col lg={6}>
                            <Form.Control
                              className="mb-2"
                              type="text"
                              placeholder="Old Trafford"
                              value={orderdetails?.delivaraddress?.address}
                              readOnly
                            />
                          </Col>
                          <Col lg={6}>
                            <Form.Control
                              type="text"
                              className="mb-2"
                              placeholder="Stretford"
                              value={orderdetails?.delivaraddress?.city}
                              readOnly
                            />
                          </Col>
                          <Col lg={6}>
                            <Form.Control
                              type="text"
                              className="mb-2"
                              placeholder="Manchester"
                              value={orderdetails?.delivaraddress?.country}
                              readOnly
                            />
                          </Col>
                          <Col lg={6}>
                            <Form.Control
                              type="text"
                              placeholder="M16 ORA"
                              value={orderdetails?.delivaraddress?.postcode}
                              readOnly
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Order Notes</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder={orderdetails?.returnreason}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Images</Form.Label>
                        <div className="product-images">
                          {orderdetails?.productdetails?.images.map(function (
                            imageobject
                          ) {
                            return (
                              <img
                                src={imageobject?.title}
                                crossOrigin="anonymous"
                              />
                            );
                          })}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>MARK AS</h2>
                <Form.Select
                  aria-label="Default select example"
                  name="dispatch"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  value={status}
                >
                  <option value="0">Pending</option>
                  <option value="1">Dispatched</option>
                  <option value="2">Cancelled</option>
                  <option value="3">Return Requested</option>
                  <option value="4">Returned</option>
                </Form.Select>
              </div>
            </div>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>ADD TRACKING NUMBER</h2>
                <Form.Control
                  className="mb-2"
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    settrackingnumber(e.target.value);
                  }}
                  value={trackingnumber}
                />
                <div className="blog-save-btn">
                  <div className="cmn-btn">
                    <button
                      type="submit"
                      onClick={() => {
                        submitform();
                      }}
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="product-actions">
              <h2>ACTIVITY</h2>
              <hr />
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
