import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import { useDispatch, useSelector } from "react-redux";
import { listnotificationAction } from "../Redux/Action/AdminstratorAction";
import moment from "moment";
import Pagination from "../Component/Pagination";
export default function PushNotificationArchive() {

  const [pageno, setPageno] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(listnotificationAction({
      perpage:perpage,
      pageno:pageno,
      serachkeyval:serachkeyval
    }))

  },[pageno, perpage,serachkeyval]);

  const notificationlist = useSelector((state) => state.Administrator.notificationlist);
  const paginationevent = (pag) => {
    setPageno(pag);
  };

  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Push Notificiations</h2>
        </div>
        <hr />
        <Container fluid className="back-white">
          <div className="inner-tab-cmn">
            <Link className="" to="/PushNotifications">
              Members
            </Link>
            <Link className="active" to="/PushNotificationArchive">
              Archive
            </Link>
            <Link className="" to="/Push-Notification-Create">
              Create New
            </Link>
          </div>
        </Container>
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                <Form.Control type="search" placeholder="Keyword Search.." 
                   value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}



                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
      
        
                <div className="date-range-picker">
                  <DateRangePicker
                    // Call handleDateRangeApply when the DateRangePicker is closed
                  />
                </div>
              </Col>



              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Created By</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
          <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;
               
              
                   return(   rangeStart +
                      " -" +
                      (notificationlist?.notificationlist?.length > 10
                        ? rangeEnd
                        : notificationlist?.notificationlist?.length) +
                      " of " +
                      notificationlist?.notificationlist?.length?.toLocaleString("en-US"))
                   
                 
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>#</th>
                <th>DATE</th>
                <th>CREATED BY</th>
                <th>CAMPAIGN NAME</th>
                <th>DETAIL</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
            {
              notificationlist?.notificationlist?.map(function(object,i){
                return(
                  <tr>
                  <td>{i + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}</td>
                  <td>
                    {moment(object?.createdAt).format("DD/MM/YYYY")}
                    <br />
                    <span className="time">
                      {" "}
                      {moment(object?.createdAt).format("HH:mm")}
                    </span>
                  </td>
                <td>{object?.admin_info?.name}</td>
                <td>{object?.title}</td>
                <td>{object?.description}</td>
                {
                  object?.date !=undefined?( <td>Scheduled</td>):( <td>Sent</td>)
                }
               
              </tr>
                )
              })
            }
           
             
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        totalpagination={notificationlist?.totalpagination}
        paginationevent={paginationevent}
      />
    </Layout>
  );
}
