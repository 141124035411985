import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import ProductChart from "../Component/ProductChart";
export default function ProductsDetailsOverview() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>CLUBERLY ISA 5.9%</h2>
            <p>PRODUCT ID # 00001</p>
          </div>
          <div>
            <div className="date-created">
              <h4>
                CREATED: <span>01/02/2023</span>
              </h4>
              <h4>
                LAST ACTIVE: <span>01/02/2023</span>
              </h4>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Products">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col lg={8}>
              <div className="product-tab-cmn-btns">
                <Link className="active buttons" to="#">
                  Overview
                </Link>
                <Link className="buttons" to="/Products-Detail-Members">
                  Members
                </Link>
                <Link className="buttons" to="/Products-Details-Clubs">
                  Clubs
                </Link>
                <Link className="buttons" to="/Products-Detail-Transactions">
                  Transactions
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>BALANCE</h6>
                  <h2>£202,330.00</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>TOTAL RAISED CLUBS</h6>
                  <h2>£21,900.00</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>TOTAL RAISED NATIONAL POT</h6>
                  <h2>£21,900.00</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>DEPOSITS LAST 24HRS</h6>
                  <h2>£10,100.00</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>WITHDRAWALS LAST 24HRS</h6>
                  <h2>£5,500.00</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>RATE</h6>
                  <h2>+5.9%</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>OPEN ACCOUNTS</h6>
                  <h2>3,234</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>CLOSED ACCOUNTS</h6>
                  <h2>324</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6}>
                <div className="product-cont-boxes">
                  <h6>CLUBS</h6>
                  <h2>1,109</h2>
                </div>
              </Col>
              <Col lg={12}>
                <div className="product-graph">
                  <ProductChart />
                </div>
              </Col>

              <Col lg={12}>
                <div className="product-table-top">
                  <h3>TOP 10 ACCOUNTS</h3>
                </div>
                <div class="table-responsive">
                  <Table size="sm" className="table-cmn account">
                    <thead>
                      <tr>
                        <th>ACCOUNT #</th>
                        <th>OPEN DATE</th>
                        <th>NAME</th>
                        <th>TOTAL DEPOSITS</th>
                        <th>BALANCE </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>Tony Jones Smith</td>
                        <td className="total-amount">+ £89,500.00</td>
                        <td className="balance">£89,500.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>Tony Jones Smith</td>
                        <td className="total-amount">+ £89,500.00</td>
                        <td className="balance">£89,500.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>Tony Jones Smith</td>
                        <td className="total-amount">+ £89,500.00</td>
                        <td className="balance">£89,500.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>Tony Jones Smith</td>
                        <td className="total-amount">+ £89,500.00</td>
                        <td className="balance">£89,500.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>Tony Jones Smith</td>
                        <td className="total-amount">+ £89,500.00</td>
                        <td className="balance">£89,500.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>Tony Jones Smith</td>
                        <td className="total-amount">+ £89,500.00</td>
                        <td className="balance">£89,500.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>Tony Jones Smith</td>
                        <td className="total-amount">+ £89,500.00</td>
                        <td className="balance">£89,500.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>Tony Jones Smith</td>
                        <td className="total-amount">+ £89,500.00</td>
                        <td className="balance">£89,500.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>Tony Jones Smith</td>
                        <td className="total-amount">+ £89,500.00</td>
                        <td className="balance">£89,500.00</td>
                      </tr>
                      <tr>
                        <td>000001</td>
                        <td>01/02/2023</td>
                        <td>Tony Jones Smith</td>
                        <td className="total-amount">+ £89,500.00</td>
                        <td className="balance">£89,500.00</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>ACCOUNT DETAIL</h2>
                <div className="product-overview-bottom">
                  <div className="bottom-top">
                    <h3>CREATED BY</h3>
                    <p>Cluberly Admin</p>
                  </div>
                  <div className="bottom-down">
                    <h3>OPENING DATE</h3>
                    <p>25th January 2023</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>LEGAL & FINANCIAL DOCUMENTS </h2>
                <div className="product-overview-bottom">
                  <div className="bottom-top d-flex justify-content-between align-items-end">
                    <div>
                      <h3>FCA DOCUMENTATION</h3>
                      <p>Uploaded: 25th January 2023</p>
                    </div>
                    <button type="button">VIEW</button>
                  </div>
                  <div className="bottom-down d-flex justify-content-between align-items-end">
                    <div>
                      <h3>PRE CONTRACT CREDIT INFORMATION</h3>
                      <p>Uploaded: 25th January 2023</p>
                    </div>
                    <button type="button">VIEW</button>
                  </div>
                  <div className="bottom-down d-flex justify-content-between align-items-end">
                    <div>
                      <h3>KEY FEATURES DOCUMENT</h3>
                      <p>Uploaded: 25th January 2023</p>
                    </div>
                    <button type="button">VIEW</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>ADMIN ACTIONS</h2>
                <button type="button" className="edit-product">
                  EDIT PRODUCT
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
