import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";

export default function TicketDetail() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Ticket 000001</h2>
            <p>DELETE ACCOUNT</p>
          </div>
          <div>
            <div className="date-created">
              <h4>
                REGISTERED: <span>01/02/2023</span>
              </h4>
              <h4>
                LAST ACTION: <span>01/02/2023</span>
              </h4>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={12}>
              <div className="product-tab-left">
                <Link to="/support">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form">
                  <h2>MEMBER INFORMATION</h2>
                  <Row>
                    <Col lg={6}>
                      <div className="member-info-inner">
                        <h2>DETAILS</h2>
                        <div className="member-inner">
                          <p>
                            Member: <span>Tony Jones Smith</span>
                          </p>
                          <p>
                            Email: <span>tonyjonessmith@gmail. com</span>
                          </p>
                          <p>
                            Phone: <span>+44 7577 202 124</span>
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="member-info-inner">
                        <h2>ADDRESS</h2>
                        <div className="address-main">
                          <p>
                            Old Trafford <br />
                            Stretford
                            <br /> Manchester
                            <br /> M16 0RA
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="profile-products-sec">
                  <h2>TICKET DETAILS </h2>
                  <hr />
                  <div className="admin-box-main">
                    <div className="admin-box-inner">
                      <div className="admin-box-top">
                        <h3 className="text-start">
                          1/02/2023<span>12:01pm</span>
                        </h3>
                      </div>
                      <p className="text-start">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Pellentesque id commodo nunc. Fusce enim ligula,
                        consectetur et velit nec, rutrum hendrerit risus. Proin
                        non tristique ipsum. Quisque nec ante dignissim,
                        lobortis tortor at, bibendum tellus. Praesent ultrices
                        odio urna, et volutpat ex placerat id. Aliquam ante
                        eros, scelerisque et leo id, convallis rhoncus odio.
                        Donec elit nibh, eleifend sit amet dignissim sed, semper
                        vitae leo.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="profile-products-sec">
                  <h2>NOTES </h2>
                  <hr />
                  <div className="admin-box-main">
                    <div className="admin-box-inner">
                      <div className="admin-box-top">
                        <h3>
                          CLUBERLY ADMIN <span>1/02/2023</span>{" "}
                          <span>12:01pm</span>
                        </h3>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Pellentesque id commodo nunc. Fusce enim ligula,
                        consectetur et velit nec, rutrum hendrerit risus. Proin
                        non tristique ipsum. Quisque nec ante dignissim,
                        lobortis tortor at, bibendum tellus. Praesent ultrices
                        odio urna, et volutpat ex placerat id. Aliquam ante
                        eros, scelerisque et leo id, convallis rhoncus odio.
                        Donec elit nibh, eleifend sit amet dignissim sed, semper
                        vitae leo.
                      </p>
                    </div>
                    <hr />
                    <div className="admin-box-inner">
                      <div className="admin-box-top">
                        <h3>
                          CLUBERLY ADMIN <span>1/02/2023</span>{" "}
                          <span>12:01pm</span>
                        </h3>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Pellentesque id commodo nunc. Fusce enim ligula,
                        consectetur et velit nec,
                      </p>
                    </div>
                    <hr />
                    <div className="admin-box-inner">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Type Here"
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>STATUS</h2>
                <Form.Select aria-label="Default select example">
                  <option>OPEN</option>
                  <option value="1">CLOSE</option>
                </Form.Select>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
