import React from "react";
import { AreaChart, Area, XAxis, YAxis, Tooltip } from "recharts";
export default function MembersChart() {
  const data = [
    {
      name: "Jan",
      uv: 1000,
    },
    {
      name: "Feb",
      uv: 1500,
    },
    {
      name: "Mar",
      uv: 3500,
    },
    {
      name: "Apr",
      uv: 4000,
    },
  ];
  return (
    <div>
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="uv"
          stackId="1"
          stroke="#1ED3B0"
          fill="#1ED3B0"
        />
      </AreaChart>
    </div>
  );
}
