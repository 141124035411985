import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AdminlistAction } from "../Redux/Action/AdminstratorAction";
import moment from "moment/moment";
import { Encryptedid } from "../Util/BcruptEncyptid";
import AdminlistsComponent from "../Component/AdminlistsComponent";
export default function SuperAdmin() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      AdminlistAction({
        role: 0,
      })
    );
  }, []);

  const Adminlist = useSelector((state) => state.Administrator.Adminlist);
  const navigate = useNavigate();

  const admindetails = (id) => {
    const bcrypt = Encryptedid(id);
    navigate("/Edit-Admin/" + bcrypt);
  };
  const recal = () => {
    dispatch(AdminlistAction());
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All Admin</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Keyword Search.." />
                </Form.Group>
              </Col>
              <Col
                xxl={2}
                xl={3}
                lg={4}
                md={4}
                className="d-flex justify-content-center"
              >
                <Form.Select aria-label="Default select example">
                  <option>Created Date Range</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Role</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn transactions">
                  <NavLink to="/Create-Admin">Create Admin</NavLink>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <ul>
                <li>
                  <Link to="/Administrators" className="active-tab">
                    ALL <span>{Adminlist?.Totaladmin}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/superadmin" className="active-tab">
                    SUPER ADMIN <span> {Adminlist?.superadmin}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/adminList">
                    ADMIN <span> {Adminlist?.Admincount}</span>
                  </Link>
                </li>
              </ul>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 - 6 of 6 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <AdminlistsComponent
          Adminlist={Adminlist?.alllist}
          type="superadmin"
          recal={recal}
        />
      </Container>

      <div className="next-btn-fix new">
        <Link to="#">Next</Link>
      </div>
    </Layout>
  );
}
