import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import moment from "moment";
import { Encryptedid } from "../Util/BcruptEncyptid";

export default function BloglistComponent({
  getbloglists,
  paginationevent,
  uniqueid,
}) {
  uniqueid = uniqueid >= 10 ? uniqueid + 1 : uniqueid;

  return (
    <>
      <div class="table-responsive main">
        <Table size="sm" className="table-cmn">
          <thead>
            <tr>
              <th>BLOG #</th>
              <th>CREATED</th>
              <th>CREATED BY</th>
              <th>BLOG TITLE</th>
              <th>NEWS </th>
              <th>STATUS</th>
              <th>LAST EDITED</th>
              <th>LAST EDITED BY</th>
            </tr>
          </thead>
          <tbody>
            {getbloglists?.alllist?.map(function (bolgobject, i) {
              return (
                <tr>
                  <td>{uniqueid++}</td>
                  <td>
                    {moment(bolgobject?.createdAt).format("DD/MM/YYYY")}
                    <br />
                    <span className="time">
                      {" "}
                      {moment(bolgobject?.createdAt).format("HH:mm")}
                    </span>
                  </td>
                  <td>
                    <Link to={"/Edit-OLD-Blog/" + Encryptedid(bolgobject?._id)}>
                      {bolgobject?.Author}
                    </Link>
                  </td>
                  <td>{bolgobject?.blogtitle}</td>
                  <td>{bolgobject?.publishnewtab == 0 ? "No" : "Yes"}</td>
                  <td>
                    {bolgobject?.publishstatus == 0
                      ? "Published"
                      : bolgobject?.publishstatus == 2
                      ? "Closed"
                      : "Draft"}
                  </td>

                  <td>
                    {moment(bolgobject?.updatedAt).format("DD/MM/YYYY")}
                    <br />
                    <span className="time">
                      {" "}
                      {moment(bolgobject?.updatedAt).format("HH:mm")}
                    </span>
                  </td>
                  <td>{bolgobject?.Admin_details?.name}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Pagination
        totalpagination={getbloglists?.totalpagination}
        paginationevent={paginationevent}
      />
    </>
  );
}
