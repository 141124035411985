import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addandupdateblogAction } from "../Redux/Action/AdminstratorAction";
import { useState } from "react";
import Editor from "../Component/TextEditor";
import { EditorState } from "draft-js";
export default function CreateNewBlog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState();
  const [selectedDImage, setSelectedDImage] = useState();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  // const handleEditorChange = (state) => {
  //   setEditorState(state);
  //   convertContentToHTML();
  // };
  // const convertContentToHTML = () => {
  //   let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
  //   setConvertedContent(currentContentAsHTML);
  // };
  // const createMarkup = (html) => {
  //   return {
  //     __html: DOMPurify.sanitize(html),
  //   };
  // };
  const formik = useFormik({
    initialValues: {
      blogtitle: "",
      Author: "",
      coverimage: "",
      content: "",
      publishstatus: 0,
      publishnewtab: 0,
    },
    validationSchema: Yup.object().shape({
      blogtitle: Yup.string().required("Blog title is required"),
      Author: Yup.string().required("Author is required"),
      coverimage: Yup.string(),
      publishstatus: Yup.string(),
      publishnewtab: Yup.string(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const formdata = new FormData();
      formdata.append("blogpic", selectedImage);

      formdata.append("content", convertedContent);
      formdata.append("blogtitle", values.blogtitle);
      formdata.append("Author", values.Author);
      formdata.append("coverimage", values.coverimage);
      formdata.append("publishstatus", values.publishstatus);
      formdata.append("publishnewtab", values.publishnewtab);

      dispatch(addandupdateblogAction(formdata)).then(function () {
        navigate("/Blogs");
      });
    },
  });

  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Create New Blog Post</h2>
            <p>NEW POST</p>
          </div>
          <div></div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Blogs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <form onSubmit={formik.handleSubmit}>
        <Container fluid>
          <Row>
            <Col lg={8}>
              <Row>
                <Col lg={12}>
                  <div className="customer-form-new">
                    <h2>BASIC DETAILS</h2>
                    <hr className="cmn-border" />
                    <div className="customer-form-inner">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Blog Title</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="blogtitle"
                              value={formik.values.blogtitle}
                            />
                            {formik.submitCount > 0 &&
                              formik.errors.blogtitle && (
                                <div className="errors">
                                  {formik.errors.blogtitle}
                                </div>
                              )}
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Author</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="Author"
                              value={formik.values.Author}
                            />
                            {formik.submitCount > 0 && formik.errors.Author && (
                              <div className="errors">
                                {formik.errors.Author}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3 image-upload-main">
                            <Form.Label>Cover Image</Form.Label>
                            <div className="input-image-show">
                              <Form.Control
                                type="file"
                                placeholder="Upload Image"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  setSelectedImage(e.target.files[0]);
                                  setSelectedDImage(
                                    URL?.createObjectURL(e.target.files[0])
                                  );
                                }}
                                name="coverimage"
                                value={formik.values.coverimage}
                              />

                              <p>Upload Image</p>
                            </div>
                            {formik.submitCount > 0 &&
                              formik.errors.coverimage && (
                                <div className="errors">
                                  {formik.errors.coverimage}
                                </div>
                              )}
                          </Form.Group>
                          <img
                            className="upload-img-show"
                            src={selectedDImage}
                            alt="Thumb"
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="customer-form-new mt-4 mb-4">
                    <h2>CONTENT</h2>
                    <hr className="cmn-border" />
                    <div className="rich-text">
                      <Editor
                        name="description"
                        onChange={(data) => {
                          setData(data);
                        }}
                        editorLoaded={editorLoaded}
                      />

                      {/* {JSON.stringify(data)} */}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="h-100">
              <div className="product-overview-right">
                <div className="product-overview-box">
                  <h2>PUBLISH STATUS</h2>
                  <Form.Select
                    aria-label="Default select example"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="publishstatus"
                    value={formik.values.publishstatus}
                  >
                    <option value="0">PUBLISH</option>
                    <option value="1">DRAFT</option>
                  </Form.Select>
                  {formik.submitCount > 0 && formik.errors.publishstatus && (
                    <div className="errors">{formik.errors.publishstatus}</div>
                  )}

                  <div className="blog-save-btn">
                    <div className="cmn-btn">
                      <button type="submit">SAVE</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-overview-right mt-4 ">
                <div className="product-overview-box blog-bottom">
                  <h2>PUBLISH TO NEWS TAB</h2>
                  <Form.Select
                    aria-label="Default select example"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="publishnewtab"
                    value={formik.values.publishnewtab}
                  >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </Form.Select>
                  {formik.submitCount > 0 && formik.errors.publishnewtab && (
                    <div className="errors">{formik.errors.publishnewtab}</div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </form>
    </Layout>
  );
}
