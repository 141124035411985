import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import Products from "./pages/Products";
import Members from "./pages/Members";
import Clubs from "./pages/Clubs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Support from "./pages/Support";
import Administrators from "./pages/Administrators";
import ErrorLogs from "./pages/ErrorLogs";
import Blogs from "./pages/Blogs";
import Files from "./pages/Files";
import MailingList from "./pages/MailingList";
import PushNotifications from "./pages/PushNotifications";
import Transactions from "./pages/Transactions";
import Dashboard from "./pages/Dashboard";
import ProductsDetailsOverview from "./pages/ProductsDetailOverview";
import ProductsDetailsMembers from "./pages/ProductsDetailMembers";
import ProductsDetailsClubs from "./pages/ProductsDetailClubs";
import ProductsDetailsTransactions from "./pages/ProductsDetailTransactions";
import CreateProduct from "./pages/CreateProduct";
import CustomerProfileOverview from "./pages/CustomerProfileOverview";
import CustomerProfileProductOverview from "./pages/CustomerProfileProductOverview";
import CustomerProfileTransactions from "./pages/CustomerProfileTransactions";
import CustomerProductDetail from "./pages/CustomerProductDetail";
import ClubProfileOverview from "./pages/ClubProfileOverview";
import ClubProfileMember from "./pages/ClubProfileMember";
import ClubProfileTransactions from "./pages/ClubProfileTransactions";
import NewClubTransactions from "./pages/NewClubTransactions";
import TicketDetail from "./pages/TicketDetail";
import CreateAdmin from "./pages/CreateAdmin";
import CreateNewBlog from "./pages/CreateNewBlog";
import MailingListArchive from "./pages/MailingListArchive";
import CreateNewMail from "./pages/CreateNewMail";
import PushNotificationArchive from "./pages/PushNotificationArchive";
import PushNotificationCreate from "./pages/PushNotificationCreate";
import UploadDocument from "./pages/UploadDocument";
import EditAdmin from "./pages/EditAdmin";
import SuperAdmin from "./pages/SuperAdmin";
import AdminList from "./pages/AdminList";
import CreateClub from "./pages/CreateClub";
import { PrivateRoute } from "./Protected/PrivateRoute";
import ResetOtp from "./pages/ResetOtp";
import Resetpassword from "./pages/Resetpassword";
import EditOldBlog from "./pages/EditOldBlog";
import Memberresetpassword from "./pages/Memberresetpassword";
import Players from "./pages/Players";
import CreatePlayer from "./pages/CreatePlayer";
import PlayerProfileOverview from "./pages/PlayerProfileOverview";
import PlayerProfileMember from "./pages/PlayerProfileMember";
import PlayerProfileTransactions from "./pages/PlayerProfileTransactions";
import NewPlayerTransactions from "./pages/NewPlayerTransaction";
import ManageOrders from "./pages/ManageOrders";
import ManageOrdersReturned from "./pages/ManageOrdersReturned";
import OrderDetail from "./pages/OrderDetail";
import ReturnOrderDetail from "./pages/ReturnOrderDetail";
import ManageStocks from "./pages/ManageStocks";
import CreateNewProduct from "./pages/CreateNewProduct";
import OrderView from "./pages/OrderView";
import ManageCategory from "./pages/ManageCategory";
import CreateCategory from "./pages/CreateCategory";
import EditCategory from "./pages/EditCategory";
import ManageReport from "./pages/ManageReport";

function App() {
  const isAuthenticated = localStorage.getItem("accessToken");
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {!isAuthenticated && (
            <>
              <Route path="/" element={<Login />} />
              <Route path="/Otp/:id" element={<LoginOtp />} />
              <Route path="/ResetOtp/:id" element={<ResetOtp />} />
              <Route path="/Resetpassword/:id" element={<Resetpassword />} />
              <Route path="/Forgot-Password" element={<ForgotPassword />} />
              <Route
                path="/Memberresetpassword/:id"
                element={
                  <PrivateRoute>
                    <Memberresetpassword />{" "}
                  </PrivateRoute>
                }
              />
            </>
          )}

          {isAuthenticated && (
            <>
              <Route path="*" element={<Navigate to="/Dashboard" />} />
            </>
          )}

          <Route
            path="/Products"
            element={
              <PrivateRoute>
                <Products />
              </PrivateRoute>
            }
          />
          <Route
            path="/Resetpassword/:id"
            element={
              <PrivateRoute>
                <Resetpassword />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/Memberresetpassword/:id"
            element={
              <PrivateRoute>
                <Memberresetpassword />{" "}
              </PrivateRoute>
            }
          />

          <Route
            path="/Members"
            element={
              <PrivateRoute>
                <Members />
              </PrivateRoute>
            }
          />
          <Route
            path="/Clubs"
            element={
              <PrivateRoute>
                <Clubs />
              </PrivateRoute>
            }
          />
          <Route
            path="/Support"
            element={
              <PrivateRoute>
                <Support />
              </PrivateRoute>
            }
          />
          <Route
            path="/Administrators"
            element={
              <PrivateRoute>
                <Administrators />
              </PrivateRoute>
            }
          />
          <Route
            path="/superadmin"
            element={
              <PrivateRoute>
                <SuperAdmin />
              </PrivateRoute>
            }
          />

          <Route
            path="/adminList"
            element={
              <PrivateRoute>
                <AdminList />
              </PrivateRoute>
            }
          />
          <Route
            path="/ErrorLogs"
            element={
              <PrivateRoute>
                <ErrorLogs />
              </PrivateRoute>
            }
          />
          <Route
            path="/Blogs"
            element={
              <PrivateRoute>
                <Blogs />
              </PrivateRoute>
            }
          />
          <Route
            path="/Files"
            element={
              <PrivateRoute>
                <Files />
              </PrivateRoute>
            }
          />
          <Route
            path="/MailingList"
            element={
              <PrivateRoute>
                <MailingList />
              </PrivateRoute>
            }
          />
          <Route
            path="/PushNotifications"
            element={
              <PrivateRoute>
                <PushNotifications />
              </PrivateRoute>
            }
          />
          <Route
            path="/Transactions"
            element={
              <PrivateRoute>
                <Transactions />
              </PrivateRoute>
            }
          />
          <Route
            path="/Dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/Products-Details-Overview"
            element={
              <PrivateRoute>
                <ProductsDetailsOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/Products-Detail-Members"
            element={
              <PrivateRoute>
                <ProductsDetailsMembers />
              </PrivateRoute>
            }
          />
          <Route
            path="/Products-Details-Clubs"
            element={
              <PrivateRoute>
                <ProductsDetailsClubs />
              </PrivateRoute>
            }
          />
          <Route
            path="/Products-Detail-Transactions"
            element={
              <PrivateRoute>
                <ProductsDetailsTransactions />
              </PrivateRoute>
            }
          />
          <Route
            path="/Create-Product"
            element={
              <PrivateRoute>
                <CreateProduct />
              </PrivateRoute>
            }
          />
          <Route
            path="/Customer-Profile-Overview/:id"
            element={
              <PrivateRoute>
                <CustomerProfileOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/Customer-Profile-product-Overview"
            element={
              <PrivateRoute>
                <CustomerProfileProductOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/Customer-Profile-Transactions"
            element={
              <PrivateRoute>
                <CustomerProfileTransactions />
              </PrivateRoute>
            }
          />
          <Route
            path="/Customer-Product-Detail"
            element={
              <PrivateRoute>
                <CustomerProductDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/Club-Profile-Overview/:id"
            element={
              <PrivateRoute>
                <ClubProfileOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/Club-Profile-Member"
            element={
              <PrivateRoute>
                <ClubProfileMember />
              </PrivateRoute>
            }
          />
          <Route
            path="/Club-Profile-Transactions"
            element={
              <PrivateRoute>
                <ClubProfileTransactions />
              </PrivateRoute>
            }
          />
          <Route
            path="/New-Club-Transactions"
            element={
              <PrivateRoute>
                <NewClubTransactions />
              </PrivateRoute>
            }
          />
          <Route
            path="/Ticket-Detail"
            element={
              <PrivateRoute>
                <TicketDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/Create-Admin"
            element={
              <PrivateRoute>
                <CreateAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/Edit-Admin/:id"
            element={
              <PrivateRoute>
                <EditAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="/Edit-OLD-Blog/:id"
            element={
              <PrivateRoute>
                <EditOldBlog />
              </PrivateRoute>
            }
          />
          <Route
            path="/Create-New-Blog"
            element={
              <PrivateRoute>
                <CreateNewBlog />
              </PrivateRoute>
            }
          />
          <Route
            path="/Mailing-List-Archive"
            element={
              <PrivateRoute>
                <MailingListArchive />
              </PrivateRoute>
            }
          />
          <Route
            path="/Create-New-Mail"
            element={
              <PrivateRoute>
                <CreateNewMail />
              </PrivateRoute>
            }
          />
          <Route
            path="/Push-Notification-Archive"
            element={
              <PrivateRoute>
                <PushNotificationArchive />
              </PrivateRoute>
            }
          />
          <Route
            path="/Push-Notification-Create"
            element={
              <PrivateRoute>
                <PushNotificationCreate />
              </PrivateRoute>
            }
          />
          <Route
            path="/Upload-Document"
            element={
              <PrivateRoute>
                <UploadDocument />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-club"
            element={
              <PrivateRoute>
                <CreateClub />
              </PrivateRoute>
            }
          />
          <Route
            path="/players"
            element={
              <PrivateRoute>
                <Players />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-player"
            element={
              <PrivateRoute>
                <CreatePlayer />
              </PrivateRoute>
            }
          />
          <Route
            path="/player-profile-overview"
            element={
              <PrivateRoute>
                <PlayerProfileOverview />
              </PrivateRoute>
            }
          />
          <Route
            path="/player-profile-member"
            element={
              <PrivateRoute>
                <PlayerProfileMember />
              </PrivateRoute>
            }
          />
          <Route
            path="/player-profile-transactions"
            element={
              <PrivateRoute>
                <PlayerProfileTransactions />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-player-transactions"
            element={
              <PrivateRoute>
                <NewPlayerTransactions />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-orders"
            element={
              <PrivateRoute>
                <ManageOrders />
              </PrivateRoute>
            }
          />
          <Route
            path="/orders-returned"
            element={
              <PrivateRoute>
                <ManageOrdersReturned />
              </PrivateRoute>
            }
          />
          <Route
            path="/order-detail/:id"
            element={
              <PrivateRoute>
                <OrderDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/return-order-detail"
            element={
              <PrivateRoute>
                <ReturnOrderDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-stocks"
            element={
              <PrivateRoute>
                <ManageStocks />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-new-product"
            element={
              <PrivateRoute>
                <CreateNewProduct />
              </PrivateRoute>
            }
          />
          <Route
            path="/order-view/:id"
            element={
              <PrivateRoute>
                <OrderView />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-category"
            element={
              <PrivateRoute>
                <ManageCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-category"
            element={
              <PrivateRoute>
                <CreateCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-category/:id"
            element={
              <PrivateRoute>
                <EditCategory />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-report"
            element={
              <PrivateRoute>
                <ManageReport />
              </PrivateRoute>
            }
          />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
