import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid } from "../Util/BcruptEncyptid";
import {
  accountsuspendedAction,
  deleteAdminuserAction,
  memberprofiledetailsAction,
  updatememberprofileAction,
  uploadDocumentAction,
} from "../Redux/Action/AdminstratorAction";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import DeleteComponent from "../Component/DeleteComponent";
import Membersuspended from "../Component/Membersuspended";
import { resetpasswordlinkAction } from "../Redux/Action/Authaction";
import Documentuplomodal from "../Component/Documentuplomodal";

export default function PlayerProfileOverview() {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(
  //     memberprofiledetailsAction({
  //       _id: idvalue,
  //     })
  //   ).then(function (data) {
  //     if (data.payload) {
  //       var Details = data.payload;
  //       for (const [key, value] of Object.entries(Details)) {
  //         if (key == "datebirth") {
  //           formik.setFieldValue(
  //             "datebirth",
  //             moment(value).format("YYYY-MM-DD"),
  //             { strict: false }
  //           );
  //         } else {
  //           formik.setFieldValue(key, value, { strict: false });
  //         }
  //       }
  //     }
  //   });
  // }, [idvalue]);

  // const formik = useFormik({
  //   initialValues: {
  //     firstname: memberdetails?.firstname || "",
  //     surname: memberdetails?.surname || "",
  //     email: memberdetails?.email || "",
  //     phone: memberdetails?.phone || "",
  //     address1: memberdetails?.address1 || "",
  //     city: memberdetails?.city || "",
  //     state: memberdetails?.state || "",
  //     postcode: memberdetails?.postcode || "",
  //     datebirth: memberdetails?.datebirth || "",
  //   },
  //   validationSchema: Yup.object().shape({
  //     surname: Yup.string().required("Sur Name is required"),
  //     datebirth: Yup.string().required("Date of birth is required"),

  //     email: Yup.string()
  //       .email("Please enter a valid email")
  //       .required("Email is required"),
  //     phone: Yup.number().required("Phone is required"),
  //     address1: Yup.string().required("Address line is required"),
  //     city: Yup.string().required("City  is required"),
  //     state: Yup.string().required("State  is required"),
  //     postcode: Yup.string().required("Postcode  is required"),
  //   }),
  //   validateOnChange: false,
  //   validateOnBlur: false,
  //   onSubmit: (values) => {
  //     values.id = idvalue;
  //     dispatch(updatememberprofileAction(values)).then(function () {
  //       navigate("/Members");
  //     });
  //   },
  // });

  // const deleteadmin = () => {
  //   dispatch(
  //     deleteAdminuserAction({
  //       id: idvalue,
  //       type: 1,
  //     })
  //   ).then(function () {
  //     setShow(false);
  //     navigate("/Members");
  //   });
  // };

  // const suspend = () => {
  //   setShowsuspended(false);
  //   dispatch(
  //     accountsuspendedAction({
  //       id: idvalue,
  //       accountstatus: memberdetails.accountstatus == 1 ? 0 : 1,
  //     })
  //   );
  // };

  // // accountsuspendedAction

  // const submitdocument = (values) => {
  //   var appendform = new FormData();
  //   appendform.append("title", values.title);
  //   appendform.append("filename", values.filename);
  //   appendform.append("userid", idvalue);

  //   dispatch(uploadDocumentAction(appendform));
  //   setShownew(false);
  // };

  const getFileExtension = (url) => {
    // Split the URL by '.'
    const parts = url.split(".");
    // Get the last part which should be the extension
    return parts[parts.length - 1].toLowerCase();
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>
              <img src={require("../Assets/Images/dummy.svg").default} />
              Fox
            </h2>
          </div>
        </div>
        <hr />
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/players">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col lg={8}>
              <div className="product-tab-cmn-btns">
                <Link className="active buttons" to="/player-profile-overview">
                  Overview
                </Link>

                {/* <Link className="buttons" to="/player-profile-member">
                  Member
                </Link> */}
                <Link className="buttons" to="/player-profile-transactions">
                  Transactions
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form">
                  <h2>PLAYER INFORMATION</h2>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Tony Jones Smith"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Sur Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Tony Jones Smith"
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="tonyjones@manchesterunited.com"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Dob</Form.Label>

                        <Form.Control type="date" />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="text" placeholder="+442038806050" />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Address</Form.Label>
                        <Form.Control className="mb-2" type="text" />

                        <Form.Control type="text" className="mb-2" />

                        <Form.Control type="text" className="mb-2" />

                        <Form.Control type="text" />
                      </Form.Group>
                    </Col>
                    <Col
                      lg={6}
                      className="d-flex justify-content-end align-items-end"
                    >
                      <div class="delete-btn">
                        <button type="submit">SUBMIT</button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>ADMIN ACTIONS</h2>
                <button type="button" className="edit-product">
                  RESET PASSWORD
                </button>
                <button type="button" className="edit-product">
                  SUSPEND ACCOUNT
                </button>
                <button type="button" className="edit-product">
                  DELETE / CLOSE ACCOUNT
                </button>
              </div>
            </div>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>ADMIN ACTIONS</h2>
                <button type="button" className="edit-product">
                  APPROVE / DECLINE APPLICATION
                </button>
                <button type="button" className="edit-product">
                  SUSPEND / DELETE PROFILE
                </button>
              </div>
            </div>
            {/* <div className="product-actions">
              <h2>ACTIVITY</h2>
              <hr />
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>

      {/* <DeleteComponent
        show={show}
        handleClose={handleClose}
        type={"customer"}
        Id={idvalue}
        deleteadmin={deleteadmin}
      />
      <Membersuspended
        show={showsuspended}
        handleClose={handleClosesus}
        type={"customer"}
        Id={idvalue}
        deleteadmin={suspend}
      />

      <Documentuplomodal
        documentshows={documentshows}
        documentclose={documentclose}
        submitdocument={submitdocument}
      /> */}
    </Layout>
  );
}
