import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getclublistAction,
  getproductlistAction,
} from "../Redux/Action/AdminstratorAction";
import moment from "moment";
import { Encryptedid } from "../Util/BcruptEncyptid";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import Pagination from "../Component/Pagination";
import Accordion from "react-bootstrap/Accordion";
export default function ManageStocks() {
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [perpage, setPerpage] = useState(10);
  const [tab, setTab] = useState(1);
  const [serachkeyval, setSerachkeyval] = useState("");
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getproductlistAction({
        perpage: perpage,
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval]);

  const productlist = useSelector((state) => state.Administrator.productlist);

  console.log(productlist, "productlistproductlist");
  const admindetails = (id) => {
    const bcrypt = Encryptedid(id);
    navigate("/Club-Profile-Overview/" + bcrypt);
  };
  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };
  const paginationevent = (pag) => {
    setPageno(pag);
  };

  const totalquality = (product_object) => {
    // Initialize total quantity outside the map function
    var totalQuantity = 0;
    product_object?.variants?.map(function (object) {
      // Check if object and object.quantity are defined before using them
      if (object && object.quantity) {
        totalQuantity += parseInt(object.quantity); // Accumulate quantity
      }
    });

    return totalQuantity;
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Manage Stock</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={2}
                xl={3}
                lg={3}
                md={4}
                className="d-flex justify-content-center"
              >
                <div className="date-range-picker">
                  <DateRangePicker placeholder="Select Date Range" />
                </div>
              </Col>
              <Col xxl={2} xl={3} lg={3} md={4}></Col>

              <Col
                xxl={5}
                xl={3}
                lg={3}
                md={12}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/create-new-product">CREATE NEW PRODUCT</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="#" className="active-tab">
                  ALL STOCK
                  <span>{productlist?.Totalproduct}</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;

                  return (
                    rangeStart +
                    " -" +
                    (productlist?.Totalproduct > 10
                      ? rangeEnd
                      : productlist?.Totalproduct) +
                    " of " +
                    productlist?.Totalproduct?.toLocaleString("en-US")
                  );
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive main listing ">
          <Accordion className="listing-tab-header">
            <Accordion.Item>
              <Accordion.Header>
                <Table size="sm" className="table-cmn mb-0">
                  <tr>
                    <th className="width-set">Product ID</th>
                    <th className="width-set">Created Date</th>
                    <th className="width-set">
                      <Link to=""> Name</Link>
                    </th>
                    <th className="width-set">Available Quantity</th>

                    <th className="width-set">Sold Quantity</th>
                    <th className="width-set">Status</th>
                  </tr>

                  {/* <ul>
              <li>Product ID</li>
              <li>Created Date</li>
              <li className="width-set-head">
                <div>Product Name</div>
              </li>
              <li>Available Quantity</li>
              <li>Sold Quantity</li>

              <li>Status</li>
            </ul> */}
                </Table>
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey={[]} alwaysOpen>
            {productlist?.alllist?.map(function (product_object, i) {
              return (
                <Accordion.Item eventKey={i}>
                  <Accordion.Header>
                    <Table className="table-cmn listing">
                      <tr>
                        <td className="width-set">
                          {i + 1 + (pageno > 1 ? (pageno - 1) * 10 : 0)}
                        </td>
                        <td className="width-set">
                          {" "}
                          {moment(product_object?.createdAt).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td className="width-set">
                          <Link
                            to={
                              "/order-view/" + Encryptedid(product_object?._id)
                            }
                          >
                            {" "}
                            {product_object?.title.slice(0, 100)}{" "}
                          </Link>
                        </td>
                        <td className="width-set">
                          {totalquality(product_object)}
                        </td>
                        <td className="width-set">{product_object?.soldquantity}</td>
                        <td className="width-set">
                          {product_object?.publicstatus == 0
                            ? "Draft"
                            : "Publish"}
                        </td>
                      </tr>
                    </Table>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table className="table-cmn listing">
                      {product_object?.variants?.map(function (
                        object,
                        varindex
                      ) {


                       

                    






                        return (
                          <tr>
                            <td className="width-set">
                            <img src={require('../Assets/Images/arrow.png')} />
                            
                            
                            </td>
                            <td className="width-set">
                              {" "}
                              {moment(product_object?.createdAt).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td className="width-set">
                              {" "}
                              <Link
                                to={
                                  "/order-view/" +
                                  Encryptedid(product_object?._id)
                                }
                              >
                               

                                {object?.size && `${object?.size}`}
                                  {object?.size !== null && object?.color !== null && "/"}
                                  {object?.color}
                              </Link>
                            </td>
                            <td className="width-set">{object?.quantity}</td>
                            <td className="width-set">{object?.soldquantity}</td>
                            <td className="width-set">
                              ---
                            </td>
                          </tr>
                        );
                      })}
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </Container>

      <Pagination
        totalpagination={productlist?.totalpagination}
        paginationevent={paginationevent}
      />
    </Layout>
  );
}
