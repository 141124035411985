import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
export default function Documentuplomodal({
  documentshows,
  documentclose,
  submitdocument,
}) {
  const formik = useFormik({
    initialValues: {
      title: "",
      filename: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Document title is required"),
      filename: Yup.string().required("File is required"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      submitdocument(values);
      formik.resetForm();
    },
  });

  return (
    <Modal
      show={documentshows}
      onHide={documentclose}
      className="delete-popup upload"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Col lg={12} className="mb-4">
            <h4>Add Document</h4>
          </Col>
          <Col lg={12}>
            <Form.Group className="mb-3 mt-3 image-upload-main">
              <Form.Label>Document Title</Form.Label>
              <Form.Control
                className=""
                type="text"
                name="title"
                value={formik.values.title}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.submitCount > 0 && formik.errors.title && (
                <div className="errors">{formik.errors.title}</div>
              )}
              <Form.Label className="mt-3"> Document Upload</Form.Label>
              <div className="input-image-show">
                <Form.Control
                  type="file"
                  placeholder="Upload Image"
                  name="filename"
                  onChange={(e) => {
                    formik.setFieldValue("filename", e.target.files[0], {
                      strict: false,
                    });
                  }}
                />

                <p>Upload Image</p>
              </div>
              {formik.submitCount > 0 && formik.errors.filename && (
                <div className="errors">{formik.errors.filename}</div>
              )}
            </Form.Group>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
