import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";

export default function MailingListArchive() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Mailing List</h2>
        </div>
        <hr />
        <Container fluid className="back-white">
          <div className="inner-tab-cmn">
            <Link className="" to="/MailingList">
              Members
            </Link>
            <Link className="active" to="/Mailing-List-Archive">
              Archive
            </Link>
            <Link className="" to="/Create-New-Mail">
              Create New
            </Link>
          </div>
        </Container>
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Keyword Search.." />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Date Range</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Created By</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 - 10 of 20 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>#</th>
                <th>DATE</th>
                <th>CREATED BY</th>
                <th>SUBJECT</th>
                <th>INCLUDE</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>00001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>Super Admin</td>
                <td>Hey you! New products are waiting.</td>
                <td>Members, Clubs, Charity, School</td>
                <td>Scheduled</td>
              </tr>
              <tr>
                <td>00001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>Super Admin</td>
                <td>Hey you! New products are waiting.</td>
                <td>Members, Clubs, Charity, School</td>
                <td>Sent</td>
              </tr>
              <tr>
                <td>00001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>Super Admin</td>
                <td>Hey you! New products are waiting.</td>
                <td>Members, Clubs, Charity, School</td>
                <td>Draft</td>
              </tr>
              <tr>
                <td>00001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>Super Admin</td>
                <td>Hey you! New products are waiting.</td>
                <td>Members, Clubs, Charity, School</td>
                <td>Scheduled</td>
              </tr>
              <tr>
                <td>00001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>Super Admin</td>
                <td>Hey you! New products are waiting.</td>
                <td>Members, Clubs, Charity, School</td>
                <td>Sent</td>
              </tr>
              <tr>
                <td>00001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>Super Admin</td>
                <td>Hey you! New products are waiting.</td>
                <td>Members, Clubs, Charity, School</td>
                <td>Draft</td>
              </tr>
              <tr>
                <td>00001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>Super Admin</td>
                <td>Hey you! New products are waiting.</td>
                <td>Members, Clubs, Charity, School</td>
                <td>Scheduled</td>
              </tr>
              <tr>
                <td>00001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>Super Admin</td>
                <td>Hey you! New products are waiting.</td>
                <td>Members, Clubs, Charity, School</td>
                <td>Sent</td>
              </tr>
              <tr>
                <td>00001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>Super Admin</td>
                <td>Hey you! New products are waiting.</td>
                <td>Members, Clubs, Charity, School</td>
                <td>Draft</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>

      <div className="next-btn-fix new">
        <Link to="#">Next</Link>
      </div>
    </Layout>
  );
}
