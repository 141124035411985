import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import TransactionChart from "../Component/TransactionChart";

export default function Transactions() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Transactions</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Keyword Search.." />
                </Form.Group>
              </Col>
              <Col>
                <div className="date-range-picker">
                  <DateRangePicker placeholder="Select Date Range" />
                </div>
              </Col>
              <Col>
                <Form.Select aria-label="Default select example">
                  <option>TRANSACTION TYPE</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn transactions">
                  <Link to="#">DOWNLOAD CSV</Link>
                </div>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn transactions">
                  <Link to="#">DOWNLOAD PDF</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="#" className="active-tab">
                  ALL <span>1</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  NEW TODAY <span> 0</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  DEPOSITS <span>0</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  PAYOUTS <span>0</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <Row className="transaction-box-wrap">
          <Col xxl={8} xl={8} lg={8}>
            <Row>
              <Col xxl={4} xl={4} lg={6} md={6}>
                <div className="transactions-box">
                  <h4>DEPOSITS</h4>
                  <h2>£0</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6} md={6}>
                <div className="transactions-box">
                  <h4>WITHDRAWALS</h4>
                  <h2>£0</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6} md={6}>
                <div className="transactions-box">
                  <h4>INTEREST ADDED</h4>
                  <h2>£0</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6} md={6}>
                <div className="transactions-box">
                  <h4>CLUB PAYOUTS</h4>
                  <h2>£0</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6} md={6}>
                <div className="transactions-box">
                  <h4>NATIONAL POT PAYOUTS</h4>
                  <h2>£0</h2>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={6} md={6}>
                <div className="transactions-box">
                  <h4>NET BALANCE</h4>
                  <h2>£0</h2>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xxl={4} xl={4} lg={4}>
            <div className="transaction-graph">
              <TransactionChart />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 - 1 of 1 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>TICKET ID</th>
                <th>DATE / TIME</th>
                <th>MEMBER / CLUB NAME</th>
                <th>DESCRIPTION</th>
                <th>TRANSACTION TYPE </th>
                <th>AMOUNT</th>
                <th>BALANCE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>Tony Jones Smith</td>
                <td>CLUBERLY ISA 0</td>
                <td>DEPOSIT</td>
                <td>
                  <h6>+ 0</h6>
                </td>
                <td>
                  <h6>0</h6>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>

      <div className="next-btn-fix new">
        <Link to="#">Next</Link>
      </div>
    </Layout>
  );
}
