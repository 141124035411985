import moment from "moment";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Encryptedid } from "../Util/BcruptEncyptid";
import { useNavigate } from "react-router-dom";
import DeleteComponent from "./DeleteComponent";
import { useDispatch } from "react-redux";
import { deleteAdminuserAction } from "../Redux/Action/AdminstratorAction";
import Pagination from "./Pagination";

export default function AdminlistsComponent({
  Adminlist,
  type,
  recal,
  paginationevent,
}) {
  const navigate = useNavigate();
  const admindetails = (id) => {
    const bcrypt = Encryptedid(id);
    navigate("/Edit-Admin/" + bcrypt);
  };
  const [show, setShow] = useState(false);
  const [Id, setid] = useState("");

  const handleClose = () => {
    setShow(false);
    setid("");
  };
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  const deleteadmin = () => {
    dispatch(
      deleteAdminuserAction({
        id: Id,
        type: 0,
      })
    ).then(function () {
      navigate("/" + type);
      setShow(false);
      recal();
    });
  };

  return (
    <>
      <div class="table-responsive main">
        <Table size="sm" className="table-cmn">
          <thead>
            <tr>
              <th>ID #</th>
              <th>CREATED</th>
              <th>NAME</th>
              <th>ROLE</th>
              <th>PERMISSIONS </th>
              <th>LAST ACTIVE</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {Adminlist?.map(function (object_administrator, i) {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    {moment(object_administrator?.createdAt).format(
                      "DD/MM/YYYY"
                    )}
                    <br />
                    <span className="time">
                      {" "}
                      {moment(object_administrator?.createdAt).format("HH:mm")}
                    </span>
                  </td>
                  <td
                    onClick={() => {
                      admindetails(object_administrator?._id);
                    }}
                  >
                    <div>{object_administrator?.name}</div>
                  </td>
                  <td>
                    {(() => {
                      if (object_administrator?.role === 0) {
                        return "Super Admin";
                      } else if (object_administrator?.role === 1) {
                        return "Admin";
                      } else {
                        return "Developer";
                      }
                    })()}
                  </td>
                  <td>Full Access</td>
                  <td>
                    {moment(object_administrator?.updatedAt).format(
                      "DD/MM/YYYY"
                    )}
                    <br />
                    <span className="time">
                      {" "}
                      {moment(object_administrator?.updatedAt).format("HH:mm")}
                    </span>
                  </td>
                  <td>
                    <div
                      className="delete-btn"
                      onClick={() => {
                        setShow(true);
                        setid(object_administrator?._id);
                      }}
                    >
                      <button type="button">DELETE</button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <DeleteComponent
          show={show}
          handleClose={handleClose}
          type={type}
          Id={Id}
          deleteadmin={deleteadmin}
        />
      </div>
      <Pagination
        totalpagination={Adminlist?.totalpagination}
        paginationevent={paginationevent}
      />
    </>
  );
}
