import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getclublistAction } from "../Redux/Action/AdminstratorAction";
import moment from "moment";
import { Encryptedid } from "../Util/BcruptEncyptid";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import Pagination from "../Component/Pagination";
export default function ManageOrdersReturned() {
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [perpage, setPerpage] = useState(10);
  const [tab, setTab] = useState(1);
  const [serachkeyval, setSerachkeyval] = useState("");
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getclublistAction({
        perpage: perpage,
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval]);

  const getclublists = useSelector((state) => state.Administrator.getclublists);
  const admindetails = (id) => {
    const bcrypt = Encryptedid(id);
    navigate("/Club-Profile-Overview/" + bcrypt);
  };
  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };
  const paginationevent = (pag) => {
    setPageno(pag);
  };
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Returned Orders</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={2}
                xl={3}
                lg={3}
                md={4}
                className="d-flex justify-content-center"
              >
                <div className="date-range-picker">
                  <DateRangePicker placeholder="Select Date Range" />
                </div>
              </Col>
              <Col xxl={2} xl={3} lg={3} md={4}></Col>

              <Col
                xxl={5}
                xl={3}
                lg={3}
                md={12}
                className="d-flex justify-content-end align-items-center"
              ></Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/manage-orders">
                  ALL ORDERS
                  <span>10</span>
                </Link>
              </li>
              <li>
                <Link to="/orders-returned" className="active-tab">
                  RETURNED ORDERS
                  <span>10</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive main">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Date of Request</th>
                <th>Product Name</th>
                <th>Reason for Return</th>

                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>000001</td>
                <td>
                  01/03/2024
                  <br />
                  <span class="time"> 18:56</span>
                </td>
                <td>
                  <Link to="/return-order-detail"> Lorem Ipsum </Link>
                </td>
                <td>
                  <div>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting industry.
                  </div>
                </td>

                <td>Active</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>

      {/* <Pagination
        totalpagination={getclublists?.totalpagination}
        paginationevent={paginationevent}
      /> */}
    </Layout>
  );
}
