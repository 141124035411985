import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import RevenueChart from "../Component/RevenueChart";
import MembersChart from "../Component/MembersChart";
import { useDispatch, useSelector } from "react-redux";
import { dashBoardAdminAction } from "../Redux/Action/AdminstratorAction";
export default function Dashboard() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

    useEffect(() => {
      dispatch(
        dashBoardAdminAction()
      );
     
    }, []);

    const dashboard = useSelector((state) => state.Administrator.dashboard);
    console.log(dashboard,"dashboarddashboarddashboard")


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Dashboard</h2>
        </div>
      </div>
      <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={8} xl={8} lg={9}>
              <div className="dashbox-inner-wrap">
                <div className="d-flex justify-content-between align-items-center">
                  <h2>REVENUE OVERVIEW</h2>
                  <Form.Select aria-label="Default select example">
                    <option>All Time</option>
                    <option value="1">10</option>
                    <option value="2">20</option>
                    <option value="3">30</option>
                  </Form.Select>
                </div>
                <Row>
                  <Col xxl={4} xl={4} lg={4}>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL DEPOSITS</h6>
                      <h4>£0</h4>
                      {/* <p>10% increase from last month</p> */}
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={4}>
                    <div className="dash-inner-boxes">
                      <h6>RAISED FOR CLUBS</h6>
                      <h4>£0</h4>
                      {/* <p>10% increase from last month</p> */}
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={4}>
                    <div className="dash-inner-boxes">
                      <h6>RAISED FOR NATIONAL POT</h6>
                      <h4>£0</h4>
                      {/* <p>10% increase from last month</p> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={3}>
              <div className="dashbox-side">
                <h2>ACCOUNTS OVERVIEW</h2>
                <div className="dash-inner-boxes">
                  <h6>ACCOUNT CURRENT </h6>
                  <h4>£0</h4>
                  {/* <p>10% increase from last month</p> */}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="dash-bottom">
            <Col xxl={4} xl={4} lg={4}>
              <div className="dash-graph">
                <h3>Revenue</h3>
                <RevenueChart />
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <div className="dashbox-side">
                <h2>MEMBERS </h2>
                <div className="dash-inner-boxes">
                  <h6>TOTAL MEMBERS </h6>
                  <h4>{dashboard?.Totalmember}</h4>
                  {/* <p>10% increase from last month</p> */}
                </div>
                <div className="dash-inner-boxes">
                  <h6>TOTAL CLUBS </h6>
                  <h4>{dashboard?.Totalclub}</h4>
                  {/* <p>10% increase from last month</p> */}
                </div>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4}>
              <div className="dash-graph">
                <h3>Members</h3>
                <MembersChart />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
