import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import BloglistComponent from "../Component/BloglistComponent";
import { useEffect } from "react";
import { getbloglistAction } from "../Redux/Action/AdminstratorAction";
import { useDispatch, useSelector } from "react-redux";
export default function Blogs() {
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const [tab, setTab] = useState(1);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [perpage, setPerpage] = useState(10);
  const navigate = useNavigate();
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  const dispatch=useDispatch();
  useEffect(() => {
    dispatch(
      getbloglistAction({
        perpage: perpage,
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval]);

  const getbloglists = useSelector((state) => state.Administrator.getbloglists);

  const paginationevent = (pag) => {
    setPageno(pag);
  };

  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Blog</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
           
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              <Col>
                {/* <Form.Select aria-label="Default select example">
                      <option>Created Date</option>
                      <option value="1">10</option>
                      <option value="2">20</option>
                      <option value="3">30</option>
                    </Form.Select> */}
                <div className="date-range-picker">
                  <DateRangePicker placeholder="Select Date Range" />
                </div>
              </Col>
              <Col>
                <Form.Select aria-label="Default select example">
                  <option>Created By</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>
              <Col>
                <Form.Select aria-label="Default select example">
                  <option>Features In News</option>
                  <option value="1">10</option>
                  <option value="2">20</option>
                  <option value="3">30</option>
                </Form.Select>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link to="/Create-New-Blog">CREATE NEW</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
    

            <ul>
              <li>
                <Link
                  to="#"
                  className={tab == 1 && "active-tab"}
                  onClick={() => {
                    setTab(1);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  ALL <span>{getbloglists?.Totalblog}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 2 && "active-tab"}
                  onClick={() => {
                    setTab(2);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  PUBLISHED <span>{getbloglists?.publishblog}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 3 && "active-tab"}
                  onClick={() => {
                    setTab(3);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  DRAFTS <span>{getbloglists?.draftblog}</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
        <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {
                  
                  (() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;
                  if (tab === 1) {
                    return (
                      rangeStart +
                      " -" +
                     ( getbloglists?.Totalblog >10? rangeEnd : getbloglists?.Totalblog)+
                      " of " +
                      getbloglists?.Totalblog?.toLocaleString("en-US")
                    );
                  } else if (tab === 2) {
                    return `${rangeStart} - ${getbloglists?.publishblog >10? rangeEnd : getbloglists?.publishblog} of  ${getbloglists?.publishblog?.toLocaleString(
                      "en-US"
                    )} `;
                  } else {
                    return `${rangeStart} - ${getbloglists?.draftblog >10? rangeEnd : getbloglists?.draftblog} of  ${getbloglists?.draftblog?.toLocaleString(
                      "en-US"
                    )} `;
                  }
                })()}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <BloglistComponent 

          getbloglists={getbloglists}
          paginationevent={paginationevent}
          uniqueid={uniqueid}

        />
      </Container>

   
    </Layout>
  );
}
