import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";

export default function CreateNewMail() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>New Mail Shot</h2>
           
          </div>
          <div>
         
          </div>
        </div>
        <hr />
      </div>

      <Container fluid className="back-white">
        <div className="inner-tab-cmn">
          <Link className="" to="/MailingList">
            Members
          </Link>
          <Link className="" to="/Mailing-List-Archive">
            Archive
          </Link>
          <Link className="active" to="/Create-New-Mail">
            Create New
          </Link>
        </div>
      </Container>
      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form-new">
                  <h2>BASIC DETAILS</h2>
                  <hr className="cmn-border" />
                  <div className="customer-form-inner">
                    <Row>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Subject</Form.Label>
                          <Form.Control type="text" placeholder="Type Here" />
                        </Form.Group>
                      </Col>

                      <Col lg={12}>
                        <Form.Group className="mb-3 pb-3">
                          <Form.Label>Send to</Form.Label>
                          <div className="create-mail-checks">
                            <div className="check-box-inner">
                              <label>Members</label>
                              <Form>
                                {["checkbox"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="group1"
                                      type={type}
                                      id={`inline-${type}-1`}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>

                            <div className="check-box-inner">
                              <label>Clubs</label>
                              <Form>
                                {["checkbox"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="group1"
                                      type={type}
                                      id={`inline-${type}-1`}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                            <div className="check-box-inner">
                              <label>Charities</label>
                              <Form>
                                {["checkbox"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="group1"
                                      type={type}
                                      id={`inline-${type}-1`}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                            <div className="check-box-inner">
                              <label>Schools</label>
                              <Form>
                                {["checkbox"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="group1"
                                      type={type}
                                      id={`inline-${type}-1`}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div>
                        </Form.Group>
                        <hr className="cmn-border" />
                      </Col>
                      <Col lg={3}>
                        <Form.Group className="mt-4 ">
                          <Form.Label>Scheduled Date & Time</Form.Label>
                          <Form.Control type="date" placeholder="Type Here" />
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group className="mt-4 ">
                          <Form.Label>Scheduled Date & Time</Form.Label>
                          <Form.Control type="time" placeholder="Type Here" />
                        </Form.Group>
                      </Col>
                      <Col
                        lg={6}
                        className="d-flex justify-content-end align-items-end"
                      >
                        <div className="cmn-btn">
                          <button type="button">SCHEDULE</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4} className="h-100">
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>PUBLISH STATUS</h2>
                <Form.Select aria-label="Default select example">
                  <option>DRAFT</option>
                  <option value="1">CLOSE</option>
                </Form.Select>

                <div className="blog-save-btn">
                  <div className="cmn-btn">
                    <button type="button">SAVE / SEND</button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
