import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pushnotificationallowmemberlistAction } from "../Redux/Action/AdminstratorAction";
import Pagination from "../Component/Pagination";

export default function PushNotifications() {
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [serachkeyval, setSerachkeyval] = useState("");
  const [device_type, setdevice_type] = useState([1,2]);
  const [perpage, setPerpage] = useState(10);
 
  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(pushnotificationallowmemberlistAction({
      perpage:perpage,
      pageno:pageno,
      serachkeyval:serachkeyval,
      device_type:device_type
    }))

  },[pageno, perpage,serachkeyval,device_type]);

  const notificationallowuserlist = useSelector((state) => state.Administrator.notificationallowuserlist);
  const paginationevent = (pag) => {
    setPageno(pag);
  };

  
  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Push Notificiations</h2>
        </div>
        <hr />
        <Container fluid className="back-white">
          <div className="inner-tab-cmn">
            <Link className="active" to="">
              Members
            </Link>
            <Link className="" to="/Push-Notification-Archive">
              Archive
            </Link>
            <Link className="" to="/Push-Notification-Create">
              Create New
            </Link>
          </div>
        </Container>
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Keyword Search.." 
                   value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}



                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example" onClick={(e)=>{
                  if(e.target.value !=""){
                    setdevice_type([parseInt(e.target.value)])
                  }
                
                }}>
                  <option value="">Device Type</option>
                  <option value="1">Android</option>
                  <option value="2">iOS</option>
             
                </Form.Select>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
           


            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing{" "}
                {(() => {
                  let rangeStart = pageno === 1 ? 1 : perpage * (pageno - 1);
                  let rangeEnd = perpage * pageno;
               
              
                   return(   rangeStart +
                      " -" +
                      (notificationallowuserlist?.totaluser > 10
                        ? rangeEnd
                        : notificationallowuserlist?.totaluser) +
                      " of " +
                      notificationallowuserlist?.totaluser?.toLocaleString("en-US"))
                   
                 
                })()}{" "}
                results
              </div>
            </Col>



            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive main">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>NAME</th>
                <th>DEVICE TYPE</th>
                <th>DEVICE ID</th>
                <th className="d-flex justify-content-end">
                  <div className="cmn-btn">
                    <Link to="#">DOWNLOAD CSV</Link>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
            {
              notificationallowuserlist?.userlists?.map(function(userobject,i){
                return(
                  <tr>
                <td>{userobject?.firstname+""+userobject?.surname}</td>
             
                <td>{userobject?.device_type==1?"Android":"iOS"}</td>
                <td>{userobject?.device_token.slice(0, 10) + (userobject?.device_token.length > 10 ? "..." : "")}</td>

                <td></td>
              </tr>
                )
              })
            }
             
             
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        totalpagination={notificationallowuserlist?.totalpagination}
        paginationevent={paginationevent}
      />
    </Layout>
  );
}
