import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getclublistAction,
  orderlistAction,
} from "../Redux/Action/AdminstratorAction";
import moment from "moment";
import { Encryptedid } from "../Util/BcruptEncyptid";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import Pagination from "../Component/Pagination";
export default function ManageOrders() {
  const [pageno, setPageno] = useState(1);
  const [serachkey, setSerachkey] = useState("");
  const [perpage, setPerpage] = useState(10);
  const [tab, setTab] = useState(0);
  const [serachkeyval, setSerachkeyval] = useState("");
  const [uniqueid, setUniqueid] = useState(
    pageno == 1 ? 1 : perpage * (pageno - 1)
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      orderlistAction({
        perpage: perpage,
        pageno: pageno,
        tab: tab,
        serachkeyval: serachkeyval,
      })
    );
    setUniqueid(pageno == 1 ? 1 : perpage * (pageno - 1));
  }, [pageno, perpage, tab, serachkeyval]);

  const orderlist = useSelector((state) => state.Administrator.orderlist);

  const admindetails = (id) => {
    const bcrypt = Encryptedid(id);
    navigate("/Club-Profile-Overview/" + bcrypt);
  };
  const keyPress = (e) => {
    if (e.key === "Enter") {
      setPageno(1);
      setSerachkeyval(serachkey);
    }
  };
  const paginationevent = (pag) => {
    setPageno(pag);
  };
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Manage Orders</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    value={serachkey}
                    onChange={(e) => {
                      setSerachkey(e.target.value);
                    }}
                    onKeyDown={keyPress}
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={2}
                xl={3}
                lg={3}
                md={4}
                className="d-flex justify-content-center"
              >
                <div className="date-range-picker">
                  <DateRangePicker placeholder="Select Date Range" />
                </div>
              </Col>
              <Col xxl={2} xl={3} lg={3} md={4}></Col>

              <Col
                xxl={5}
                xl={3}
                lg={3}
                md={12}
                className="d-flex justify-content-end align-items-center"
              ></Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link
                  to="#"
                  className={tab == 0 && "active-tab"}
                  onClick={() => {
                    setTab(0);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  New Orders <span>{orderlist?.totalneworder}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={tab == 5 && "active-tab"}
                  onClick={() => {
                    setTab(5);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  All Orders <span>{orderlist?.totalorder}</span>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className={tab == 1 && "active-tab"}
                  onClick={() => {
                    setTab(1);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  Dispatched
                  <span>{orderlist?.totaldistpacthorder}</span>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className={tab == 2 && "active-tab"}
                  onClick={() => {
                    setTab(2);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  Cancelled <span>{orderlist?.totalcancelorder}</span>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className={tab == 4 && "active-tab"}
                  onClick={() => {
                    setTab(4);
                    setPerpage(10);
                    setPageno(1);
                  }}
                >
                  Returns <span>{orderlist?.totalreturnorder}</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              {/* <div className="show-results">Showing 1 results</div> */}
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  value={perpage}
                  onChange={(e) => {
                    setPerpage(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option value="50">50</option>
                  <option value="80">80</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive main">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Created Date</th>
                <th>Quantity</th>
                <th>Product Name</th>

                <th>Ordered By</th>
                <th>Club Sponsor</th>
                <th>Total Amount</th>
                <th>Dispatch Date</th>

                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {orderlist?.allorder?.map(function (orderobject, i) {
                return (
                  <tr key={i}>
                    <td>{orderobject?.order_id}</td>
                    <td>
                      {moment(orderobject?.createdAt).format("DD/MM/YYYY")}
                      <br />
                      <span className="time">
                        {" "}
                        {moment(orderobject?.createdAt).format("HH:mm")}
                      </span>
                    </td>

                    <td>
                      <Link
                        to={"/order-detail/" + Encryptedid(orderobject?._id)}
                      >
                        {" "}
                        {orderobject?.quantity}
                      </Link>
                    </td>

                    <td>
                      <Link
                        to={"/order-detail/" + Encryptedid(orderobject?._id)}
                      >
                        {" "}
                        {orderobject?.productdetails?.title.substring(0, 50)}
                      </Link>
                    </td>
                    <td>
                      <div>
                        {orderobject?.userdetails?.firstname +
                          " " +
                          orderobject?.userdetails?.surname}
                      </div>
                    </td>
                    <td>xyz</td>
                    <td>
                      {orderobject?.price?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "GBP",
                      })}
                    </td>

                    <td>
                      {orderobject?.dispatchdate != undefined &&
                        moment(orderobject?.dispatchdate).format("DD/MM/YYYY")}
                    </td>
                    <td>
                      {(() => {
                        if (orderobject?.status === 0) {
                          return "Pending";
                        } else if (orderobject?.status === 1) {
                          return "Dispatched";
                        } else if (orderobject?.status === 2) {
                          return "Cancelled";
                        } else if (orderobject?.status === 3) {
                          return "Return Requested ";
                        } else if (orderobject?.status === 4) {
                          return "Returned";
                        } else {
                          return "delivered";
                        }
                      })()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        totalpagination={orderlist?.totalpagination}
        paginationevent={paginationevent}
      />
    </Layout>
  );
}
