import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useParams } from "react-router-dom";
import { Decryptedid } from "../Util/BcruptEncyptid";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { checkotpAction } from "../Redux/Action/Authaction";
import { useFormik } from "formik";
import * as Yup from 'yup'

export default function LoginOtp() {
  const {id}=useParams();
  const dispatch=useDispatch();

  var idvalue = Decryptedid(atob(id));





  const formik=useFormik({
    initialValues:{
      otp_email:"",
     
    },
    validationSchema:Yup.object().shape({
      otp_email:Yup.number().required('Otp is required'),
     
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit:(values)=>{
      values.id=idvalue;
      dispatch(checkotpAction(values))

    }
  })





  // checkotpAction
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.png")} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">OTP</h2>
                <Form onSubmit={formik.handleSubmit}>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>OTP</Form.Label>
                      <Form.Control type="text" placeholder="Type Here"   name="otp_email" onBlur={formik.handleBlur}
                      onChange={formik.handleChange}

                      />
                       {formik.errors.otp_email && <div className="errors">{formik.errors.otp_email}</div>}
                    </Form.Group>
                  </div>
                  <button
                 
                    variant="primary"
                    type="submit"
                    className="submit"
                  >
                    Log In
                  </button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
